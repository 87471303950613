import React, { useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import OrderCheckoutForm from './OrderCheckoutForm';
import AuthContext from '../../context/AuthContext';
import OrderCheckoutGuestForm from './OrderCheckoutGuestForm';
// Learning
// To best leverage Stripe’s advanced fraud functionality,
// include this script on every page, not just the checkout page.
// This allows Stripe to detect anomalous behavior that may be indicative
// of fraud as customers browse your website.
// Note: This is why we are adding it to a Layout component.

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// TIP
// call loadStripe outside of a component
// in that way there's no chance it will get
// called more times than it needs to

export default function OrderCheckout() {
  const { token } = useContext(AuthContext);

  return (
    <Elements stripe={stripePromise}>
      {token ? <OrderCheckoutForm /> : <OrderCheckoutGuestForm />}
    </Elements>
  );
}
