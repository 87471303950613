import React, { useContext } from 'react';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';

export default function SlideManageItem({ index, slide, setEdit }) {
  const { token } = useContext(AuthContext);

  async function deleteProd(slideId) {
    const authStr = 'Bearer ' + token;

    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/slides/delete/' + slideId,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={index % 2 ? 'row mt-2 bg-white' : 'row mt-2'}>
        <div className='col-1'>{slide.name}</div>
        <div className='col-1'>{slide.nameCN}</div>
        <div className='col-1'>{slide.seqNr}</div>

        <div className='col-2'>
          <img
            src={process.env.REACT_APP_ASSET_URL + '/' + slide.image}
            style={{ width: '3rem', height: '2rem' }}
            className='img-fluid'
            alt='slide'
          />
        </div>
        <div className='col-6'>{slide.link}</div>

        <div className='col-1'>
          <button className='btn btn-link' onClick={() => setEdit(slide._id)}>
            <i className='fas fa-edit' />
          </button>
          {!slide.onShelf && (
            <button
              className='btn btn-link'
              onClick={() => deleteProd(slide._id)}
            >
              <i className='fas fa-trash-alt' />
            </button>
          )}
        </div>

        <div className='col-11'>{slide.note}</div>
        <hr />
      </div>
    </>
  );
}
