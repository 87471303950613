import React, { useContext } from 'react';

import productContext from '../../context/ProductContext';
import ProductItem from './ProductItem';
import './Product.css';

export default function ProductsSales() {
  const { products } = useContext(productContext);

  return (
    <div className='container-md'>
      <div>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb p-2'>
            <li className='breadcrumb-item'>
              <a href='/'>Home</a>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              Salses
            </li>
          </ol>
        </nav>
        <h3>Sales</h3>
      </div>

      <div className='row row-cols-2 row-cols-md-3 row-cols-lg-4 g-1'>
        {products
          .filter((prod) => prod.discount > 0)
          .map((product, index) => {
            return <ProductItem product={product} key={index} />;
          })}
      </div>
    </div>
  );
}
