import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import SlideManageColumns from './SlideManageColumns';
import SlideManageItem from './SlideManageItem';
import SlideManageAdd from './SlideManageAdd';
import SlideManageEdit from './SlideManageEdit';

export default function SlideManage() {
  const [slides, setSlides] = useState();

  const [editSlideId, setEditSlideId] = useState('');
  const setEdit = (slideId) => {
    setEditSlideId(slideId);
  };

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const resSlides = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/slides'
        );
        resSlides &&
          resSlides.data.length > 0 &&
          resSlides.data.sort((a, b) => a.seqNr - b.seqNr);
        setSlides([...resSlides.data]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSlides();
  }, []);

  return (
    <div className='container-fluid bg-light text-dark'>
      <h1 className='text-center'>Manage Slides</h1>
      <SlideManageColumns />
      <h5>Add a New Slide:</h5>
      <SlideManageAdd />
      <h5>Slide List:</h5>
      <hr />
      {slides &&
        slides.map((slide, index) => {
          if (slide._id === editSlideId) {
            return (
              <SlideManageEdit key={slide._id} index={index} slide={slide} />
            );
          } else {
            return (
              <SlideManageItem
                key={slide._id}
                index={index}
                slide={slide}
                setEdit={setEdit}
              />
            );
          }
        })}
    </div>
  );
}
