import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, Dropdown, NavDropdown } from 'react-bootstrap';

import AuthModal from '../User/AuthModal';
import './Navbar.css';
import AuthContext from '../../context/AuthContext';
import ProductContext from '../../context/ProductContext';
import CartContext from '../../context/CartContext';

export default function HomeNavbar() {
  const [showTeas, setShowTeas] = useState(false);
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { token, admin, logout, langEn, userName } = useContext(AuthContext);
  const { categories } = useContext(ProductContext);
  const { cart } = useContext(CartContext);

  const nrInCart = cart
    ? cart.reduce((count, item) => count + item.quantity, 0)
    : 0;

  useEffect(() => {
    const handler = () => {
      // shrink navbar brand img when scroll down
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById('navbar-brand-img').style.width = '60px';
        document.getElementById('navbar-brand-img').style.padding = '0 0 0 8px';
        document.getElementById('navbar-brand-filler').style.width = '60px';
        return true;
      }
      // restore navbar brand img when scroll to top
      if (
        document.body.scrollTop < 80 &&
        document.documentElement.scrollTop < 80
      ) {
        document.getElementById('navbar-brand-img').style.width = '160px';
        document.getElementById('navbar-brand-img').style.padding =
          '15px 15px 10px 15px';
        document.getElementById('navbar-brand-filler').style.width = '160px';
        return false;
      }
    };
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  return (
    <>
      <div className='navbar-header d-none d-md-block'>
        {/* top header also disappear when navbar collapse - not lg */}
        <h1>
          <i>Back To The Foundation To Enjoy</i>
        </h1>
      </div>
      <Navbar
        collapseOnSelect
        variant='dark'
        expand='md'
        sticky='top'
        id='navbar'
      >
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Brand href='/'>
          <img
            src={'/logo-true-tea-short.jpg'}
            alt='true tea logo'
            id='navbar-brand-img'
            className='d-md-inline-block d-none'
          />
          <img
            src={'/logo-true-tea-short.jpg'}
            alt='true tea logo'
            id='navbar-brand-img-sm'
            className='d-inline-block d-md-none'
          />
        </Navbar.Brand>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav id='navbar-brand-filler' className='d-md-inline-block d-none' />
          <Nav>
            <Nav.Link className='px-3' href='/productCategories'>
              Our Teas
            </Nav.Link>
            <Nav.Link className='px-3 d-none' href='/products/sales'>
              Sales
            </Nav.Link>
            <Dropdown
              onMouseEnter={() => setShowTeas(true)}
              onMouseLeave={() => setShowTeas(false)}
            >
              <Dropdown.Toggle
                id='dropdown-basic'
                className='nav-link px-3 d-none'
                href='/productCategories'
              >
                Our Teas
              </Dropdown.Toggle>

              <Dropdown.Menu id='dropdown-menu' show={showTeas}>
                {categories.map((cat, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      id='dropdown-menu-item'
                      href={`/productCategory/${cat.name.replace(/\s/g, '')}`}
                    >
                      {cat.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Link className='px-3' href='/heritage'>
              Heritage
            </Nav.Link>
            <Nav.Link className='px-3' href='/aboutus'>
              About Us
            </Nav.Link>
          </Nav>
          <Nav.Link className='ms-auto' href='/message'>
            <i className='fas fa-envelope-open-text'></i>
          </Nav.Link>
          <Nav.Link href='/products/search'>
            <i className='fas fa-search'></i>
          </Nav.Link>

          {token ? (
            <>
              {admin ? (
                <Dropdown
                  onMouseEnter={() => setShowAdminMenu(true)}
                  onMouseLeave={() => setShowAdminMenu(false)}
                >
                  <Dropdown.Toggle id='dropdown-basic'>
                    <span className='text-danger'>
                      <i className='fas fa-user-cog'></i>
                      <span className='d-md-inline'>
                        {userName.split(' ')[0]}
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu show={showAdminMenu} id='dropdown-menu'>
                    <Dropdown.Item
                      href='/products/manage'
                      className='text-primary'
                      id='navdropdown-item'
                    >
                      Manage Products
                    </Dropdown.Item>
                    <NavDropdown.Item
                      href='/products/cats'
                      className='text-primary'
                    >
                      Manage Categories
                    </NavDropdown.Item>
                    <NavDropdown.Item href='/orders' className='text-primary'>
                      {langEn ? 'Manage Orders' : '订单管理'}
                    </NavDropdown.Item>
                    <Dropdown.Item href='/myorders'>
                      <span role='img' aria-label='order'>
                        🧾 {langEn ? 'MyOrders' : '我的订单'}
                      </span>
                    </Dropdown.Item>
                    <NavDropdown.Item
                      href='/slides/manage'
                      className='text-primary'
                    >
                      Manage Carousel
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href='/user/manage'
                      className='text-warning'
                    >
                      {langEn ? 'Manage Users' : '用户管理'}
                    </NavDropdown.Item>
                    <Dropdown.Item href='/user/update'>
                      <span role='img' aria-label='address'>
                        🏠 {langEn ? 'MyProfile' : '我的地址'}
                      </span>
                    </Dropdown.Item>
                    <NavDropdown.Item
                      href='/'
                      onClick={logout}
                      className='text-warning'
                    >
                      {langEn ? 'Logout ' : '退出 '}
                      <i className='fas fa-sign-out-alt'></i>
                    </NavDropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Dropdown
                  align='end'
                  onMouseEnter={() => setShowUserMenu(true)}
                  onMouseLeave={() => setShowUserMenu(false)}
                >
                  <Dropdown.Toggle id='dropdown-basic'>
                    <span className='text-warning'>
                      <i className='fas fa-user'></i>
                      <span className='d-none d-md-inline'>
                        {userName.split(' ')[0]}
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu id='dropdown-menu' show={showUserMenu}>
                    <Dropdown.Item href='/myorders'>
                      <span role='img' aria-label='order'>
                        🧾 {langEn ? 'MyOrders' : '我的订单'}
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item href='/user/update'>
                      <span role='img' aria-label='address'>
                        🏠 {langEn ? 'MyProfile' : '我的地址'}
                      </span>
                    </Dropdown.Item>
                    <NavDropdown.Item
                      className='text-warning'
                      href='/'
                      onClick={logout}
                    >
                      {langEn ? 'Logout ' : '退出 '}
                      <i className='fas fa-sign-out-alt'></i>
                    </NavDropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </>
          ) : (
            <AuthModal />
          )}

          {/* <Nav.Link href='/language'>
            <i className='fas fa-globe-asia'></i>
          </Nav.Link> */}
        </Navbar.Collapse>

        <Nav.Link href='/cart'>
          <span>
            <i className='fas fa-shopping-cart' />
          </span>
          {nrInCart > 0 && (
            <span className='badge badge-warning' id='lblCartCount'>
              {nrInCart}
            </span>
          )}
        </Nav.Link>
      </Navbar>
    </>
  );
}
