import React, { useState, useContext } from 'react';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';

export default function ProductManageEdit({ index, product, categories }) {
  const { token } = useContext(AuthContext);
  const [editProduct, setEditProduct] = useState(product);

  const [productImgs, setProductImgs] = useState([]);
  const handleSelectedImage = (e) => {
    let fileArray = [];
    for (let i = 0; i < e.target.files.length; i++) {
      fileArray.push(e.target.files[i]);
    }
    setProductImgs(fileArray);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setEditProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;
    const uploadProduct = new FormData();
    if (productImgs.length > 0) {
      // if new images are loaded, insert new image files to multer
      productImgs.map((productImg) => {
        return uploadProduct.append('productImages', productImg);
      });
      // field name 'productImage' must match backend post: upload.single('productImage')
    }

    Object.keys(editProduct).forEach(function (k) {
      if (k === 'images') {
        if (k.length > 0 && productImgs.length > 0) {
          // only insert images if new images are loaded and there are old images - to be deleted.
          editProduct.images.forEach((img) => {
            uploadProduct.append('images[]', img);
          });
        }
      } else {
        uploadProduct.append(k, editProduct[k]);
      }
    });

    // for (let pair of uploadProduct.entries()) {
    //   console.log(pair[0] + ': ' + pair[1]);
    // }

    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL +
          '/products/update/' +
          editProduct._id,
        uploadProduct,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'form row mt-2 bg-white' : 'form row mt-2'}>
        <div className='col-1'>
          <input
            required
            className='form-control'
            type='text'
            name='name'
            value={editProduct.name}
            onChange={handleChange}
            placeholder='name'
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            type='text'
            name='nameCN'
            value={editProduct.nameCN}
            onChange={handleChange}
            placeholder='名称'
          />
        </div>
        <div className='col-1'>
          <input
            required
            className='form-control'
            type='number'
            name='seqNr'
            value={editProduct.seqNr}
            onChange={handleChange}
            placeholder='sequenceNr'
          />
        </div>
        <div className='col-1'>
          <select
            className='form-select'
            name='categoryId'
            onChange={handleChange}
          >
            <option disabled value=''>
              select category
            </option>
            {categories.map((item) => {
              return (
                <option
                  key={item.name}
                  value={item._id}
                  selected={item._id === editProduct.categoryId}
                >
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className='col-2'>
          <input
            className='form-control-file'
            type='file'
            multiple
            name='image'
            onChange={handleSelectedImage}
          />
        </div>
        <div className='col-1'>
          <div className='input-group'>
            <span className='input-group-text'>$</span>
            <input
              className='form-control text-end'
              type='number'
              name='price'
              value={editProduct.price}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='col-1'>
          <div className='input-group'>
            <input
              className='form-control text-end'
              type='number'
              name='discount'
              value={editProduct.discount}
              onChange={handleChange}
            />
            <span className='input-group-text'>%</span>
          </div>
        </div>
        <div className='col-1 text-center'>
          <input
            className='form-check-input'
            type='checkbox'
            name='includeGST'
            checked={editProduct.includeGST}
            onChange={handleCheck}
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            name='unit'
            type='text'
            value={editProduct.unit}
            onChange={handleChange}
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            type='number'
            name='stock'
            value={editProduct.stock}
            onChange={handleChange}
          />
        </div>
        <div className='col-1 text-center'>
          <input
            className='form-check-input'
            type='checkbox'
            name='onShelf'
            checked={editProduct.onShelf}
            onChange={handleCheck}
          />
        </div>
      </div>

      <div className={index % 2 ? 'row bg-white' : 'row'}>
        <div className='col-2'>
          <input
            className='form-control'
            name='region'
            type='text'
            value={editProduct.region}
            onChange={handleChange}
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            name='year'
            type='text'
            value={editProduct.year}
            onChange={handleChange}
          />
        </div>
        <div className='col-8'>
          <textarea
            className='form-control'
            name='note'
            rows='3'
            value={editProduct.note}
            onChange={handleChange}
            placeholder='note'
          />
        </div>
        <div className='col-1 text-center'>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>{' '}
        <hr />
      </div>
    </form>
  );
}
