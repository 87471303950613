import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import CartContext from '../../context/CartContext';
import ProductContext from '../../context/ProductContext';
import ProductDetailsImages from './ProductDetailsImages';

function ProductDetails() {
  const { productId } = useParams();
  const { products } = useContext(ProductContext);
  const { addToCart } = useContext(CartContext);

  const product = products.find((item) => item._id === productId);
  if (!product) return <></>; // skip the first round of render, where products are not yet loaded.

  return (
    <div className='container'>
      <p></p>
      <h3>
        {product.name} ({product.nameCN})
      </h3>

      <div className='row'>
        <div className='col-md-4'>
          <ProductDetailsImages productImages={product.images} />
        </div>
        <div className='col-md-8 pt-3'>
          <table>
            <tbody>
              <tr>
                <td valign='top'>Region:</td>
                <td>{product.region}</td>
              </tr>
              <tr>
                <td valign='top'>Year:</td>
                <td>{product.year}</td>
              </tr>
              <tr>
                <td valign='top'>Note:</td>
                <td>{product.note}</td>
              </tr>
              <tr>
                <td valign='top'>
                  <b>Price:</b>
                </td>
                <td>
                  <div id='product-price'>
                    ${(product.price * (1 - product.discount / 100)).toFixed(2)}
                    /{product.unit}{' '}
                    {product.discount > 0 && (
                      <>
                        <span
                          className='text-muted'
                          id='product-original-price'
                        >
                          <s>${product.price}</s>
                        </span>{' '}
                        <span className='text-danger'>
                          {product.discount}% off
                        </span>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className='mt-2'>
            <button
              className='btn btn-sm btn-success px-2 py-1 mx-1'
              onClick={() => addToCart(productId)}
            >
              <small>
                <i className='fas fa-cart-plus'>Add to Cart</i>
              </small>
            </button>
            <a href='/cart'>
              <button className='btn btn-sm btn-outline-success px-2 py-1 mx-1'>
                <small>
                  <i className='fas fa-shopping-cart'>Go to Cart</i>
                </small>
              </button>
            </a>
            <a href='/'>
              <button className='btn btn-sm btn-outline-success px-2 py-1 mx-1'>
                <small>
                  <i className='fas fa-store'>Back to Shop</i>
                </small>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
