import React, { useContext } from 'react';

import './Product.css';
import ProductContext from '../../context/ProductContext';

export default function ProductCategoriesHeritage() {
  const { categoriesAll } = useContext(ProductContext);

  return (
    <div className='container-md'>
      {categoriesAll.map((cat, index) => {
        return (
          <section key={index}>
            <p></p>
            <h5 style={{ display: 'inline-block' }}>
              {cat.name} ({cat.nameCN})
            </h5>{' '}
            <small style={{ fontWeight: '300' }}>
              <a href={`/productCategory/${cat.name.replace(/\s/g, '')}`}>
                read more on Tea Category
              </a>
            </small>
            <section className='row mb-5'>
              <div className='col-md-6 p-0'>
                <img
                  src={process.env.REACT_APP_ASSET_URL + '/' + cat.image}
                  alt='tea'
                  className='product-img'
                />
              </div>

              <div className='product-text-holder col-md-6 border'>
                <b>
                  <a href={cat.url} target='_blank' rel='noreferrer'>
                    {cat.name}
                  </a>
                </b>
                <p> {cat.p1}</p>
                <p> {cat.p2}</p>
                <p> {cat.p3}</p>
                <p> {cat.p4}</p>
                <p> {cat.p5}</p>
                {cat.recWater && (
                  <>
                    <b>{cat.name} steeping (brewing) recommendation:</b>
                    <ul>
                      <li>Water: {cat.recWater}</li>
                      <li>Temperature: {cat.recTemp}</li>
                      <li>Time: {cat.recTime}</li>
                      <li>Times of brewing: {cat.recTimes}</li>
                    </ul>
                  </>
                )}
              </div>
            </section>
          </section>
        );
      })}
    </div>
  );
}
