import React from 'react';
import ProductCategories from './ProductCategories';

export default function ProductCategoriesOurTeas() {
  return (
    <div>
      <div className='container-md'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb p-2'>
            <li className='breadcrumb-item'>
              <a href='/'>Home</a>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              Our Teas
            </li>
          </ol>
        </nav>
        <h3>Our Teas</h3>
      </div>

      <ProductCategories />
    </div>
  );
}
