import React, { useContext } from 'react';

import CartContext from '../../context/CartContext';
import './CartItem.css';

export default function CartItem({ cartItem }) {
  const { addToCart, removeFromCart, deleteProductFromCart } =
    useContext(CartContext);

  return (
    <div className='row mb-3'>
      <div className='col-4 col-sm-2'>
        <img
          src={process.env.REACT_APP_ASSET_URL + '/' + cartItem.images[0]}
          style={{ width: '4rem' }}
          alt='product'
        />
      </div>
      <div className='col-8 col-sm-4'>
        {cartItem.name} ({cartItem.nameCN})
      </div>

      <div className='col-6 col-sm-3'>
        <div>
          <span
            className='btn cart-btn-minus'
            onClick={() => removeFromCart(cartItem._id)}
          >
            -
          </span>
          <span className='btn cart-btn-num'>{cartItem.quantity}</span>
          <span
            className='btn cart-btn-add'
            onClick={() => addToCart(cartItem._id)}
          >
            +
          </span>
        </div>
      </div>
      <div className='col-6 col-sm-3 d-flex align-items-end flex-column'>
        <h5 className='mb-0'>
          $
          {(
            cartItem.price *
            (1 - cartItem.discount / 100) *
            cartItem.quantity
          ).toFixed(2)}
        </h5>
        <small className='text-muted'>
          {cartItem.discount > 0 && <s>${cartItem.price}</s>} $
          {(cartItem.price * (1 - cartItem.discount / 100)).toFixed(2)}/
          {cartItem.unit}
        </small>
      </div>
      <div className='col-2 d-none'>
        <button
          className='btn cart-icon pt-0'
          title='remove this product'
          onClick={() => deleteProductFromCart(cartItem._id)}
        >
          <i className='fas fa-trash-alt' />
        </button>
      </div>
    </div>
  );
}
