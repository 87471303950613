/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';

import OrderItem from './OrderItem';
import AuthContext from '../../context/AuthContext';

function OrderManage() {
  const { token } = useContext(AuthContext);
  const [ordersTbDel, setOrdersTbDel] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const authStr = 'Bearer ' + token;
      try {
        const result = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/orders/allOrders',
          {
            headers: { Authorization: authStr },
          }
        );
        setOrdersTbDel([...result.data]);
      } catch (error) {
        console.error(error);
      }
    };

    token && fetchOrders();
  }, [token]);

  async function deliverOrder(orderId) {
    const authStr = 'Bearer ' + token;
    try {
      await Axios.post(
        process.env.REACT_APP_BACKEND_URL + '/orders/deliver/' + orderId,
        {},
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  async function deleteOrder(orderId) {
    const authStr = 'Bearer ' + token;
    try {
      await Axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/orders/delete/' + orderId,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <React.Fragment>
      <h1>Order Management</h1>
      <div className='container-fluid'>
        <div className='card-group'>
          {ordersTbDel.map((item) => {
            return (
              <OrderItem
                key={item._id}
                title={
                  <div>
                    {item.deliveredDate ? (
                      <div className='row'>
                        <div className='col-6'>Delivered to:</div>
                        <div className='col-6 text-end'>
                          <button
                            className='btn btn-link'
                            onClick={() => deleteOrder(item._id)}
                          >
                            <i className='fas fa-trash-alt' />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className='col-4'>
                        {/* <form onSubmit={(e) => handleSubmit(e, item)}> */}
                        <button
                          className='btn btn-outline-primary btn-sm'
                          onClick={() => deliverOrder(item._id)}
                        >
                          <i className='fas fa-truck'> Deliver</i>
                        </button>
                        {/* </form> */}
                      </div>
                    )}
                  </div>
                }
                order={item}
                // orderId={item._id}
                // paidDate={item.paidDate}
                // paymentId={item.paymentId}
                // deliveredDate={item.deliveredDate}
                // productList={item.productList}
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default OrderManage;
