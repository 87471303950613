import React, { useContext, useEffect, useState } from 'react';

import CartContext from '../../context/CartContext';
import './Product.css';

export default function ProductItem({ product }) {
  const { cart, addToCart, removeFromCart } = useContext(CartContext);
  const [qtyInCart, setQtyInCart] = useState(0);

  useEffect(() => {
    const _itemInCart = cart.find((item) => item._id === product._id);
    if (_itemInCart) {
      setQtyInCart(_itemInCart.quantity);
    } else {
      setQtyInCart(0);
    }
  }, [product._id, cart, qtyInCart]);

  return (
    <div className='col mb-1'>
      <div className='card h-100 border-success'>
        <div className='img-container m-1'>
          <a href={`/productbyid/${product._id}`}>
            <img
              className='product-img'
              src={process.env.REACT_APP_ASSET_URL + '/' + product.images[0]}
              alt='tea'
            />
          </a>
        </div>
        <div className='card-body py-0 px-2'>
          <div id='product-price'>
            ${(product.price * (1 - product.discount / 100)).toFixed(2)}/
            {product.unit}{' '}
            {product.discount > 0 && (
              <>
                <span className='text-muted' id='product-original-price'>
                  <s>${product.price}</s>
                </span>{' '}
                <span className='text-danger'>{product.discount}% off</span>
              </>
            )}
          </div>
          <div>
            {product.name} ({product.nameCN})
          </div>
        </div>
        <div className='card-footer d-grid'>
          {qtyInCart > 0 && (
            <>
              <span className='card-btn-qty'>{qtyInCart}</span>
              <button
                className='btn shadow-none card-btn-minus'
                title='remove from cart'
                onClick={() => removeFromCart(product._id)}
              >
                {/* <strong>-</strong> */}
                <i className='fas fa-minus'></i>
              </button>
            </>
          )}

          {product.stock > 0 ? (
            <button
              className='btn btn-sm btn-success m-1'
              title='add to cart'
              onClick={() => addToCart(product._id)}
            >
              <i className='fas fa-cart-plus'></i> Add to Cart
            </button>
          ) : (
            <button className='btn btn-sm btn-success mx-1' disabled>
              Out of Stock
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
