import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

import CartContext from '../../context/CartContext';
import AuthContext from '../../context/AuthContext';
import OrderItem from './OrderItem';
import './Order.css';
// import UserAddressModal from '../User/UserAddressModal';

export default function OrderCheckoutForm() {
  const { token, langEn } = useContext(AuthContext);
  const { cart, cartCostTotal, clearCart } = useContext(CartContext);
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [confirmedOrder, setConfirmedOrder] = useState();

  const [buyer, setBuyer] = useState();
  const [shipping, setShipping] = useState();

  useEffect(() => {
    const fetchOwner = async () => {
      const authStr = 'Bearer ' + token;
      try {
        const result = await axios.get(
          process.env.REACT_APP_BACKEND_URL + '/users/getUserByLoginToken',
          {
            headers: { Authorization: authStr },
          }
        );
        setBuyer(result.data);
        setShipping(result.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (token) {
      fetchOwner();
    }
  }, [token]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setShipping((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleStreetInput = (event) => {
    const { name, value } = event.target;

    setShipping((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  const stripe = useStripe();
  const elements = useElements();

  const stripeSubmit = async (event) => {
    event.preventDefault();
    const authStr = 'Bearer ' + token;

    const billingDetails = {
      name: buyer.fname + ' ' + buyer.lname,
      email: buyer.email,
      phone: buyer.phone,
      address: {
        line1: buyer.address.line1,
        city: buyer.address.suburb,
        state: buyer.address.state,
        postal_code: buyer.address.postcode,
        country: 'AU',
      },
    };

    setProcessingTo(true);

    try {
      const { data: client_secret } = await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/orders/stripepay',
        { cart, billingDetails },
        {
          headers: { Authorization: authStr },
        }
      );

      const cardElement = elements.getElement(CardElement);
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const paymentConfirm = await stripe.confirmCardPayment(client_secret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (paymentConfirm.error) {
        setCheckoutError(paymentConfirm.error.message);
        setProcessingTo(false);
        return;
      }

      // console.log('SUCCESS!', paymentConfirm.paymentIntent.payment_method);
      const { data: confirmedOrder } = await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/orders/add',
        { cart, paymentMethodReq, paymentConfirm, shipping },
        {
          headers: { Authorization: authStr },
        }
      );
      setConfirmedOrder(confirmedOrder);
      clearCart();
      setProcessingTo(false);
    } catch (error) {
      setCheckoutError(error.message);
    }
  };

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const iframeStyles = {
    base: {
      color: '#fff',
      backgroundColor: '#6772e5',
      fontSize: '15px',
      iconColor: '#fff',
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
    complete: {
      iconColor: '#cbf4c9',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    padding: '15px',
    hidePostalCode: true,
  };

  return (
    <div>
      {!confirmedOrder && buyer && shipping && (
        <>
          {/* <OrderItem
              title={<h5>{langEn ? 'Cart Summary' : '购物车内容：'}</h5>}
              order={cartOrder}
            /> */}

          <div className='card card-stripe m-2'>
            <div className='card-header' style={{ color: '#fff' }}>
              <h5>Payment Details:</h5>
            </div>

            <div className='card-stripe-element'>
              <div className='row'>
                <div className='col-6'>
                  <h5>Billing details:</h5>
                </div>
                <div className='col-6'>
                  <a href='/user/update' className='text-white'>
                    Update Profile
                  </a>
                </div>
                <div className='col-3'>Name:</div>
                <div className='col-9'>{buyer.fname + ' ' + buyer.lname}</div>
              </div>
              <div className='row'>
                <div className='col-3'>Email:</div>
                <div className='col-9'>{buyer.email}</div>
              </div>
              <div className='row'>
                <div className='col-3'>Phone:</div>
                <div className='col-9'>{buyer.phone}</div>
              </div>
              <div className='row'>
                <div className='col-3'>Address:</div>
                <div className='col-9'>
                  {buyer.address.line1}
                  <br />
                  {buyer.address.suburb}, {buyer.address.state}{' '}
                  {buyer.address.postcode}
                </div>
              </div>
            </div>

            <form onSubmit={stripeSubmit}>
              <div className='card-stripe-element'>
                <h5>Shipping details:</h5>
                <div className='row g-1 mb-1'>
                  <div className='col-2'>Name:</div>
                  <div className='col-5'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      name='fname'
                      required
                      value={shipping.fname}
                      onChange={handleChange}
                      placeholder='👤 first name'
                    />
                  </div>
                  <div className='col-5'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      name='lname'
                      required
                      value={shipping.lname}
                      onChange={handleChange}
                      placeholder='last name 👤'
                    />
                  </div>
                </div>
                <div className='row g-1 mb-1'>
                  <div className='col-2'>Email:</div>
                  <div className='col-10'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      name='email'
                      required
                      value={shipping.email}
                      onChange={handleChange}
                      placeholder='📧 email@domain'
                    />
                  </div>
                </div>
                <div className='row g-1 mb-1'>
                  <div className='col-2'>Phone:</div>
                  <div className='col-10'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      name='phone'
                      required
                      value={shipping.phone}
                      pattern='^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$'
                      onChange={handleChange}
                      placeholder='☎ Mobile Number'
                    />
                  </div>
                </div>
                <div className='row g-1 mb-1'>
                  <div className='col-2'>
                    <small>Address:</small>
                  </div>
                  <div className='col-10'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      name='line1'
                      required
                      value={shipping.address.line1}
                      onChange={handleStreetInput}
                      placeholder='📫 Street address'
                    />
                  </div>
                  <div className='col-2'></div>
                  <div className='col-5'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      name='suburb'
                      required
                      value={shipping.address.suburb}
                      onChange={handleStreetInput}
                      placeholder='suburb'
                    />
                  </div>
                  <div className='col-3'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      name='state'
                      required
                      value={shipping.address.state}
                      onChange={handleStreetInput}
                      placeholder='state'
                    />
                  </div>
                  <div className='col-2'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      name='postcode'
                      required
                      value={shipping.address.postcode}
                      onChange={handleStreetInput}
                      placeholder='postcode'
                    />
                  </div>
                </div>
              </div>

              <div className='card-stripe-element'>
                <h5>Card details:</h5>
                <CardElement
                  options={cardElementOpts}
                  onChange={handleCardDetailsChange}
                />
                <div className='my-2'>
                  <a
                    href='https://stripe.com/docs/currencies'
                    target='_blank'
                    rel='noopener noreferrer'
                    title='Users in Australia can accept Visa Mastercard American Express JCB credit and debit cards.'
                  >
                    <img
                      src={'/img/CreditCardImg/cc_visa.png'}
                      alt='visa'
                      style={{ height: '20px' }}
                    />{' '}
                    <img
                      src={'/img/CreditCardImg/cc_master.png'}
                      alt='master'
                      style={{ height: '20px' }}
                    />{' '}
                    <img
                      src={'/img/CreditCardImg/cc_american.png'}
                      alt='american'
                      style={{ height: '20px' }}
                    />{' '}
                    <img
                      src={'/img/CreditCardImg/cc_jcb.png'}
                      alt='jcb'
                      style={{ height: '20px' }}
                    />
                    <span style={{ color: '#87bbfd' }}>
                      {' '}
                      Accept major cards
                    </span>
                  </a>
                </div>
              </div>

              <div className='card-stripe-element'>
                <div className='my-1'>
                  {checkoutError && <h3>{checkoutError}</h3>}
                  <div className='my-1 text-center'>
                    <button
                      className='btn btn-light btn-stripe'
                      type='submit'
                      disabled={isProcessing || !stripe}
                      title='Your payment is secured by stripe.com'
                    >
                      <i className='fas fa-lock'></i>
                      {isProcessing
                        ? ' Processing... '
                        : ` *Pay: $${cartCostTotal.toFixed(2)} `}
                    </button>
                    <br />
                    *powered by{' '}
                    <b>
                      <a
                        href='https://stripe.com/au'
                        className='text-white'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Stripe Payment
                      </a>
                    </b>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <small>
            *The credit card information and payment are processed by
            <br />
            <a href='https://stripe.com/au' target='_blank' rel='noreferrer'>
              Stripe Payment
            </a>
            , we do not keep your credit card details.
          </small>
          {checkoutError && <div>{checkoutError}</div>}
        </>
      )}

      {confirmedOrder && buyer && (
        <>
          <h1>Payment Confirmation</h1>
          <OrderItem order={confirmedOrder} />

          <Link to='/'>
            <button className='btn btn-outline-light btn-primary m-2'>
              <i className='fas fa-store'></i>
              {langEn ? 'Back to Shop' : '回到商店'}
            </button>
          </Link>
        </>
      )}
    </div>
  );
}
