import React, { useState, useContext } from 'react';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';

function SlideManageAdd({ categories }) {
  const initSlide = {
    name: '',
    nameCN: '',
    seqNr: '',
    link: '',
    note: '',
  };

  const { token } = useContext(AuthContext);
  const [newSlide, setNewSlide] = useState(initSlide);

  const [slideImg, setSlideImg] = useState(null);
  const handleSelectedImage = (e) => {
    setSlideImg(e.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewSlide((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;

    const uploadSlide = new FormData();
    uploadSlide.append('slideImage', slideImg);
    // field name 'slideImage' must match backend post: upload.single('slideImage')

    Object.keys(newSlide).forEach(function (k) {
      uploadSlide.append(k, newSlide[k]);
    });

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/slides/add',
        uploadSlide,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    setNewSlide(initSlide);
    setSlideImg(null);
    window.location.reload();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='form row mt-2 bg-white'>
        <div className='col-1'>
          <input
            className='form-control'
            required
            type='text'
            name='name'
            value={newSlide.name}
            onChange={handleChange}
            placeholder='name'
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            type='text'
            name='nameCN'
            value={newSlide.nameCN}
            onChange={handleChange}
            placeholder='名称'
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            required
            type='number'
            name='seqNr'
            value={newSlide.seqNr}
            onChange={handleChange}
            placeholder='sequenceNr'
          />
        </div>

        <div className='col-2'>
          <input
            className='form-control-file'
            // required
            type='file'
            name='image'
            onChange={handleSelectedImage}
          />
        </div>
        <div className='col-6'>
          <input
            className='form-control'
            required
            type='text'
            name='link'
            value={newSlide.link}
            onChange={handleChange}
            placeholder='link'
          />
        </div>

        <div className='col-1'>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>

        <div className='col-11'>
          <input
            className='form-control'
            required
            type='text'
            name='note'
            value={newSlide.note}
            onChange={handleChange}
            placeholder='note'
          />
        </div>
        <hr />
      </div>
    </form>
  );
}
export default SlideManageAdd;
