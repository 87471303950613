import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import ProductContext from './ProductContext';

export default function ProductProvider(props) {
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesAll, setCategoriesAll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const resProd = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/products'
        );
        resProd &&
          resProd.data.length > 0 &&
          resProd.data.sort((a, b) => a.seqNr - b.seqNr);
        setProducts([...resProd.data.filter((item) => item.onShelf)]);
        setProductsAll([...resProd.data]);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        const resCat = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/productCats'
        );
        resCat &&
          resCat.data.length > 0 &&
          resCat.data.sort((a, b) => a.catID - b.catID);

        setCategoriesAll([...resCat.data]);
        setCategories([...resCat.data.filter((item) => item.active)]);

        // const resSubCat = await Axios.get(
        //   process.env.REACT_APP_BACKEND_URL + '/subCats'
        // );
        // setSubCats([...resSubCat.data]);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    fetchCategories();
  }, []);

  return (
    <ProductContext.Provider
      value={{
        products,
        productsAll,
        categories,
        categoriesAll,
        isLoading,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
}
