import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';

import './Home.css';
import Axios from 'axios';
import ProductCategories from '../Product/ProductCategories';

export default function Home() {
  const [slides, setSlides] = useState();

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const resSlides = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/slides'
        );
        resSlides &&
          resSlides.data.length > 0 &&
          resSlides.data.sort((a, b) => a.seqNr - b.seqNr);
        setSlides([...resSlides.data]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSlides();
  }, []);

  return (
    <div className='bg-white text-dark'>
      <div className='parallax'></div>
      {/* <h1 id='home-header'>
          Benefit from the decent teas, enjoy the classical tea culture.
        </h1> */}
      <div className='container-md mt-3'>
        <h3> True Tea, Quality Tea</h3>
      </div>
      <ProductCategories />
      <div className='container-md mt-3'>
        <h3> True Tea, True Passion</h3>
      </div>
      <Carousel
        interval='2500'
        id='home-carousel'
        className='container-md pt-4 pb-3'
      >
        {slides &&
          slides.map((item) => {
            return (
              <Carousel.Item key={item._id}>
                <a href={item.link}>
                  <img
                    className='d-block w-100'
                    src={process.env.REACT_APP_ASSET_URL + '/' + item.image}
                    alt={item.name}
                  />
                </a>
                <Carousel.Caption className='d-none d-md-block'>
                  <h3>
                    {item.name} {item.nameCN}
                  </h3>
                  <p>{item.note}</p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
      </Carousel>
    </div>
  );
}
