import React, { useState, useContext } from 'react';

import ProductCategoryColumns from './ProductCategoryColumns';
import ProductCategoryItem from './ProductCategoryItem';
import ProductCategoryAdd from './ProductCategoryAdd';
import ProductCategoryEdit from './ProductCategoryEdit';
import ProductContext from '../../context/ProductContext';

export default function ProductCategory() {
  const { categoriesAll } = useContext(ProductContext);

  const [editCattId, setEditCatId] = useState('');
  const setEdit = (catId) => {
    setEditCatId(catId);
  };

  return (
    <div className='container-fluid bg-light text-dark'>
      <h1 className='text-center'>Manage Tea Categories</h1>
      <ProductCategoryColumns />
      <h5>Add a new category:</h5>
      <ProductCategoryAdd />
      <h5>Category List:</h5>
      {categoriesAll.map((cat, index) => {
        if (cat._id === editCattId) {
          return <ProductCategoryEdit key={cat._id} index={index} cat={cat} />;
        } else {
          return (
            <ProductCategoryItem
              key={cat._id}
              index={index}
              cat={cat}
              setEdit={setEdit}
            />
          );
        }
      })}
    </div>
  );
}
