import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Axios from 'axios';

// Source: https://itnext.io/password-reset-emails-in-your-react-app-made-easy-with-nodemailer-bb27968310d7

export default function ResetPassword() {
  const { emailToken } = useParams();
  const [updateUser, setUpdateUser] = useState({});
  const [updated, setUpdated] = useState(false);
  const [errorRes, setErrorRes] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      try {
        const doc = await Axios.get(
          process.env.REACT_APP_BACKEND_URL +
            '/users/getUserByEmailToken/' +
            emailToken
        );
        if (doc.status === 200) {
          setUpdateUser({
            email: doc.data.email,
            password: '',
            emailToken: emailToken,
          });
        }
      } catch (error) {
        console.log(error.response);
        setErrorRes(error.response.data);
      }
    }
    fetchUser();
  }, [emailToken]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUpdateUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  async function updatePassword(event) {
    event.preventDefault();
    try {
      const doc = await Axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/users/resetPassword',
        updateUser
      );
      if (doc.status === 200) {
        setUpdated(true);
      }
    } catch (error) {
      console.log(error.response);
      setErrorRes(error.response.data);
    }
  }

  return (
    <>
      {errorRes ? (
        <div className='text-center'>
          <h1>Reset Password</h1>
          <h3>Error: {errorRes} </h3>
          <Link className='btn btn-warning' to='/'>
            Return
          </Link>
        </div>
      ) : (
        <div>
          {updated ? (
            <>
              <h1>Password Reset Success</h1>
              <div className='text-center'>
                <h5>Your password has been successfully reset.</h5>
                <h5>Please login.</h5>
              </div>
            </>
          ) : (
            <>
              <h1>Reset Password</h1>
              <form
                className='password-form text-center'
                onSubmit={updatePassword}
              >
                <h5>Enter New Password for {updateUser.email}:</h5>

                <input
                  //   className='form-control'
                  required
                  type='password'
                  name='password'
                  pattern='.{6,}'
                  //   value={updateUser.password}
                  placeholder='🔒Password (six+ characters)'
                  onChange={handleChange}
                />
                <br />
                <small>Password should be six or more characters</small>
                <br />
                <button className='btn btn-warning' type='submit'>
                  Set New Password
                </button>
              </form>
            </>
          )}
        </div>
      )}
    </>
  );
}
