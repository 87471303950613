import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import ProductContext from '../../context/ProductContext';
import ProductItem from './ProductItem';
import './Product.css';

export default function ProductsByCat() {
  const { cat } = useParams();
  const { products, categories } = useContext(ProductContext);

  const selectedCat = categories.find(
    (item) => item.name.replace(/\s/g, '') === cat
  );
  const productsByCat = products.filter(
    (item) => selectedCat && item.categoryId === selectedCat._id && item.onShelf
  );

  return (
    <div className='container'>
      {selectedCat && (
        <>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb p-2'>
              <li className='breadcrumb-item'>
                <a href='/'>Home</a>
              </li>
              <li className='breadcrumb-item'>
                <a href='/productCategories'>Our Teas</a>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                {selectedCat.name}
              </li>
            </ol>
          </nav>

          <h3>
            {selectedCat.name} ({selectedCat.nameCN})
          </h3>

          <section className='row mb-5'>
            <div className='col-md-6 p-0'>
              <img
                src={process.env.REACT_APP_ASSET_URL + '/' + selectedCat.image}
                alt='tea'
                className='product-img'
              />
            </div>

            <div className='product-text-holder col-md-6 border'>
              <b>
                <a href={selectedCat.url} target='_blank' rel='noreferrer'>
                  {selectedCat.name}
                </a>
              </b>
              <p> {selectedCat.p1}</p>
              <p> {selectedCat.p2}</p>
              <p> {selectedCat.p3}</p>
              <p> {selectedCat.p4}</p>
              <p> {selectedCat.p5}</p>
              {selectedCat.recWater && (
                <>
                  <b>{selectedCat.name} steeping (brewing) recommendation:</b>
                  <ul>
                    <li>Water: {selectedCat.recWater}</li>
                    <li>Temperature: {selectedCat.recTemp}</li>
                    <li>Time: {selectedCat.recTime}</li>
                    <li>Times of brewing: {selectedCat.recTimes}</li>
                  </ul>
                </>
              )}
            </div>
          </section>

          <div className='row row-cols-2 row-cols-md-3 row-cols-lg-4 g-1'>
            {productsByCat.map((product, index) => {
              return <ProductItem product={product} key={index} />;
            })}
          </div>
        </>
      )}
    </div>
  );
}
