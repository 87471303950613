import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }) => {
  const localToken = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_LS_TOKEN)
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        localToken && localToken.token && localToken.admin ? (
          <Component {...props} />
        ) : (
          <Redirect to='/' />
        )
      }
    />
  );
};

export default AdminRoute;
