import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';

export default function UserUpdate() {
  const { token } = useContext(AuthContext);
  const history = useHistory();
  const [updateUser, setUpdateUser] = useState(null);
  // ({
  //   fname: '',
  //   lname: '',
  //   email: '',
  //   password: '',
  //   phone: '',
  //   address: { line1: '', line2: '', suburb: '', state: '', postcode: '' },
  // });

  useEffect(() => {
    const fetchUser = async () => {
      const authStr = 'Bearer ' + token;
      try {
        const result = await axios.get(
          process.env.REACT_APP_BACKEND_URL + '/users/getUserByLoginToken',
          {
            headers: { Authorization: authStr },
          }
        );
        setUpdateUser(result.data);
      } catch (error) {
        console.error(error);
      }
    };
    token && fetchUser();
  }, [token]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUpdateUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleStreetInput = (event) => {
    const { name, value } = event.target;

    setUpdateUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const authStr = 'Bearer ' + token;
    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/users/updateSelf',
        updateUser,
        {
          headers: { Authorization: authStr },
        }
      );
      history.push('/');
    } catch (error) {
      console.error(error.response.data.message);
    }
  }

  return (
    <div className='container'>
      <h1>Personal Information</h1>

      {updateUser && (
        <div className='card' style={{ width: '20em' }}>
          <div className='card-header'>
            <div className='row'>
              <div className='col-12'>
                <h5>
                  Name: {updateUser.fname} {updateUser.lname}
                </h5>
              </div>
              <div className='col-3'>Email:</div>
              <div className='col-9'>{updateUser.email}</div>
              <div className='col-8'>
                <label>Membership status:</label>
              </div>
              <div className='col-4'>{updateUser.membership}</div>
              {/* <div className='col-8'>
                <label>Member Discount:</label>
              </div>
              <div className='col-4'>{memberDiscount}%</div> */}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col-3'>Phone:</div>
                <div className='col-9'>
                  <input
                    className='form-control'
                    type='text'
                    name='phone'
                    value={updateUser.phone}
                    pattern='^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$'
                    onChange={handleChange}
                    placeholder='☎ Mobile Number'
                  />
                </div>
                <div className='col-3'>
                  <label>Gender: </label>
                </div>
                <div className='col-9'>
                  <select
                    className='form-select'
                    name='gender'
                    value={updateUser.gender}
                    onChange={handleChange}
                  >
                    <option disabled value=''>
                      Gender
                    </option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                  </select>
                </div>
                <div className='col-3'>
                  <label>Age: </label>
                </div>
                <div className='col-9'>
                  <select
                    className='form-select'
                    name='age'
                    value={updateUser.age}
                    onChange={handleChange}
                  >
                    <option disabled value=''>
                      Age Range
                    </option>
                    <option value='<20'>&#60;20</option>
                    <option value='20-29'>20-29</option>
                    <option value='30-39'>30-39</option>
                    <option value='40-49'>40-49</option>
                    <option value='50-59'>50-59</option>
                    <option value='60-69'>60-69</option>
                    <option value='≥70'>&#8805;70</option>
                  </select>
                </div>
              </div>
              <div className='mb-1'>
                <label htmlFor='line1' className='form-label'>
                  Street Address:
                </label>
                <input
                  className='form-control'
                  id='line1'
                  type='text'
                  name='line1'
                  value={updateUser.address.line1}
                  onChange={handleStreetInput}
                />
              </div>
              <div className='row g-1'>
                <div className='col-6'>
                  <label>Suburb: </label>
                  <input
                    className='form-control'
                    type='text'
                    name='suburb'
                    value={updateUser.address.suburb}
                    onChange={handleStreetInput}
                  />
                </div>
                <div className='col-3'>
                  <label>State: </label>
                  <input
                    className='form-control'
                    type='text'
                    name='state'
                    value={updateUser.address.state}
                    onChange={handleStreetInput}
                  />
                </div>
                <div className='col-3'>
                  <label>Postcode: </label>
                  <input
                    className='form-control'
                    type='text'
                    name='postcode'
                    value={updateUser.address.postcode}
                    onChange={handleStreetInput}
                  />
                </div>
              </div>
            </div>
            <div className='card-footer p-3'>
              <div className='d-grid gap-2 d-flex justify-content-end'>
                <button
                  className='btn btn-outline-primary'
                  onClick={() => history.push('/')}
                >
                  <span role='img' aria-label='shop'>
                    🏠 Back to Shop
                  </span>
                </button>
                <button className='btn btn-outline-primary' type='submit'>
                  <span role='img' aria-label='shop'>
                    💾 Save
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
