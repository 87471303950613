import React from 'react';

import './Order.css';

export default function OrderItem({ title, order }) {
  return (
    <div className='card m-2' id='order-card'>
      {title && <div className='card-header'>{title}</div>}
      {order && order.buyer && (
        <div className='card-header'>
          <h5>Buyer:</h5>
          <div className='row'>
            <div className='col-3'>Name:</div>
            <div className='col-9'>
              {order.buyer.fname + ' ' + order.buyer.lname}
            </div>
            <div className='col-3'>email:</div>
            <div className='col-9'>{order.buyer.email}</div>
            <div className='col-3'>phone:</div>
            <div className='col-9'>{order.buyer.phone}</div>
            <div className='col-3'>Address:</div>
            <div className='col-9'>
              {order.buyer.address.line1}
              <br />
              {order.buyer.address.suburb} {order.buyer.address.state}{' '}
              {order.buyer.address.postcode}
            </div>
          </div>
        </div>
      )}
      {order && order.delivery && (
        <div className='card-header'>
          <h5>Deliver to:</h5>
          <div className='row'>
            <div className='col-3'>Name:</div>
            <div className='col-9'>
              {order.delivery.fname + ' ' + order.delivery.lname}
            </div>
            <div className='col-3'>email:</div>
            <div className='col-9'>{order.delivery.email}</div>
            <div className='col-3'>phone:</div>
            <div className='col-9'>{order.delivery.phone}</div>
            <div className='col-3'>Address:</div>
            <div className='col-9'>
              {order.delivery.address.line1}
              <br />
              {order.delivery.address.suburb} {order.delivery.address.state}{' '}
              {order.delivery.address.postcode}
            </div>
          </div>
        </div>
      )}
      {order && order._id && (
        <div className='card-header'>
          {order._id && (
            <div className='row'>
              <div className='col-3'>Order ID: </div>
              <div className='col-9 text-end'>{order._id}</div>
            </div>
          )}

          <div className='row'>
            <div className='col-3'>PaymentID: </div>
            <div className='col-9 text-end'>
              {order.paymentId ? order.paymentId : ''}
            </div>{' '}
          </div>
          <div className='row'>
            <div className='col-3'>ReceiptNr: </div>
            <div className='col-9 text-end'>
              {order.receipt ? order.receipt : ''}
            </div>
          </div>
          {order.paidDate && (
            <div className='row'>
              <div className='col-3'>Paid:</div>
              <div className='col-9 text-end'>
                {new Date(order.paidDate).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                }) +
                  ', ' +
                  new Date(order.paidDate).toLocaleDateString('en-AU')}
              </div>
            </div>
          )}
          {order.deliveredDate && (
            <div className='row'>
              <div className='col-3'>Delivered:</div>
              <div className='col-9 text-end'>
                {new Date(order.deliveredDate).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                }) +
                  ', ' +
                  new Date(order.deliveredDate).toLocaleDateString('en-AU')}
              </div>
            </div>
          )}
        </div>
      )}
      <div className='card-body'>
        {order &&
          order.productList.map((productItem) => {
            return (
              <div key={productItem._id} className='row'>
                <div className='col-9'>
                  {/* {admin && productItem.shelf_pos + ': '} */}
                  {productItem.name} ({productItem.nameCN})
                  {productItem.quantity > 1 && (
                    <div className='text-muted'>
                      &emsp; Qty' {productItem.quantity} @ ${productItem.price}/
                      {productItem.unit}
                    </div>
                  )}
                </div>
                <div className='col-3 text-end'>
                  ${(productItem.price * productItem.quantity).toFixed(2)}
                </div>
              </div>
            );
          })}
      </div>
      <div className='card-footer font-italic'>
        <div className='container'>
          <div className='row'>
            <div className='col-9 text-end'>Postage:</div>
            {order && order.postage && (
              <b className='col-3 text-end'>${order.postage.toFixed(2)}</b>
            )}
          </div>{' '}
          <div className='row'>
            <b className='col-9 text-end'>Total:</b>
            <b className='col-3 text-end'>
              ${order.orderTotal && order.orderTotal.toFixed(2)}
            </b>
          </div>
          {order.GSTTotal > 0 && (
            <div className='row text-muted'>
              <div className='col-9 text-end'>incl GST:</div>
              <div className='col-3 text-end'>${order.GSTTotal.toFixed(2)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
