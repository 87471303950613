import { createContext } from 'react';

const CartContext = createContext({
  cart: [],
  cartCostTotal: 0,
  setCartCostTotal: () => {},

  addToCart: () => {},
  removeFromCart: () => {},
  deleteProductFromCart: () => {},
  clearCart: () => {},
});

export default CartContext;
