import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Modal, Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactGoogleLogin from 'react-google-login';

import AuthContext from '../../context/AuthContext';
import ForgotPassword from './ForgotPassword';

export default function AuthModal() {
  const { langEn, login } = useContext(AuthContext);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const [isLoginMode, setIsLoginMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState();
  const [activating, setActivating] = useState(false);

  const [newUser, setNewUser] = useState({
    fname: '',
    lname: '',
    gender: '',
    age: '',
    email: '',
    password: '',
    password2: '',
    membership: 'member',
    phone: '',
    address: { line1: '', line2: '', suburb: '', state: '', postcode: '' },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setNewUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleStreetInput = (event) => {
    const { name, value } = event.target;

    setNewUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      let responseData = { status: 200, data: {} };
      if (!isLoginMode) {
        if (newUser.password !== newUser.password2) {
          setErrorState("Confirm password doesn't match! Try again.");
          setIsLoading(false);
          return;
        }
        responseData = await axios.post(
          process.env.REACT_APP_BACKEND_URL + '/users/register',
          {
            newUser,
            activateLink:
              process.env.REACT_APP_FRONTEND_URL + '/user/activate/',
          }
        );
      } else {
        responseData = await axios.post(
          process.env.REACT_APP_BACKEND_URL + '/users/login',
          {
            email: newUser.email,
            password: newUser.password,
          }
        );
      }
      setIsLoading(false);

      if (responseData.status === 200) {
        // logged in
        login(
          responseData.data.token,
          responseData.data.user._id,
          responseData.data.user.fname,
          responseData.data.user.admin,
          responseData.data.user.membership
        );
        setShowAuthModal(false);
      } else if (responseData.status === 201) {
        // registered - need activation
        setActivating(true);
      }
    } catch (error) {
      console.log(error.response.data);
      setIsLoading(false);
      setErrorState(error.response.data.message);
      console.error(error.response.data.message);
    }
  }

  // https://blog.prototypr.io/how-to-build-google-login-into-a-react-app-and-node-express-api-821d049ee670
  const handleGoogleLogin = async (googleData) => {
    console.log('googleData: ', googleData);
    setIsLoading(true);

    const resData = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/users/googleLogin',
      {
        googleToken: googleData.tokenId,
      }
    );
    console.log('user data: ', resData);
    setIsLoading(false);

    if (resData.status === 200) {
      // logged in
      login(
        resData.data.token,
        resData.data.user._id,
        resData.data.user.fname,
        resData.data.user.admin,
        resData.data.user.membership
      );
      setShowAuthModal(false);
    }
    // const data = await res.json();
    // store returned user somehow
  };

  return (
    <>
      <button
        className='btn btn-link link-light'
        style={{ borderRadius: '50%' }}
        onClick={() => setShowAuthModal(true)}
      >
        <i className='fas fa-user'></i>
      </button>

      <Modal show={showAuthModal} onHide={() => setShowAuthModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <ul className='nav nav-tabs card-header-tabs'>
              <li className='nav-item'>
                <button
                  className='btn btn-outline-warning'
                  onClick={() => setIsLoginMode(true)}
                >
                  <h5>
                    <i className='fas fa-user-check'></i>
                    {langEn ? 'Login' : '登录'}
                  </h5>
                </button>
              </li>
              <li className='nav-item'>
                <button
                  className='btn btn-outline-primary'
                  onClick={() => setIsLoginMode(false)}
                >
                  <h5>
                    <i className='fas fa-user-plus'></i>
                    {langEn ? 'Register' : '注册'}
                  </h5>
                </button>
              </li>
              {isLoading && <Spinner animation='border' />}
            </ul>
          </Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className='card-body'>
              <div className='form row'>
                {!isLoginMode && (
                  <>
                    <div className='mb-3 col-sm-6'>
                      <input
                        className='form-control'
                        required
                        type='text'
                        name='fname'
                        value={newUser.fname}
                        onChange={handleChange}
                        placeholder='👤 First name'
                      />
                    </div>
                    <div className='mb-3 col-sm-6'>
                      <input
                        className='form-control'
                        required
                        type='text'
                        name='lname'
                        value={newUser.lname}
                        onChange={handleChange}
                        placeholder='Last name 👤'
                      />
                    </div>

                    <div className='mb-3 col-sm-6'>
                      <input
                        className='form-control'
                        type='text'
                        name='phone'
                        value={newUser.phone}
                        pattern='^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$'
                        onChange={handleChange}
                        placeholder='☎ Mobile Number'
                      />
                    </div>
                  </>
                )}

                <div className='mb-3 col-sm-6'>
                  <input
                    className='form-control'
                    required
                    type='email'
                    name='email'
                    value={newUser.email}
                    onChange={handleChange}
                    placeholder='📧 email'
                  />
                </div>
                <div className='mb-3 col-sm-6'>
                  <input
                    className='form-control'
                    required
                    type='password'
                    name='password'
                    pattern='.{6,}'
                    value={newUser.password}
                    placeholder='🔒Password*'
                    onChange={handleChange}
                  />
                  {!isLoginMode && <small>*six or more characters.</small>}
                </div>
                {!isLoginMode && (
                  <>
                    <div className='mb-3 col-sm-6'>
                      <input
                        className='form-control'
                        required
                        type='password'
                        name='password2'
                        value={newUser.password2}
                        onChange={handleChange}
                        placeholder='🔒confirm password*'
                      />
                      <small>*six or more characters.</small>
                    </div>
                    <div className='mb-3 col-sm-6'>
                      <select
                        className='form-select'
                        name='gender'
                        value={newUser.gender}
                        onChange={handleChange}
                      >
                        <option disabled value=''>
                          Gender
                        </option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                      </select>
                    </div>
                    <div className='mb-3 col-sm-6'>
                      <select
                        className='form-select'
                        name='age'
                        value={newUser.age}
                        onChange={handleChange}
                      >
                        <option disabled value=''>
                          Age Range
                        </option>
                        <option value='<20'>&#60;20</option>
                        <option value='20-29'>20-29</option>
                        <option value='30-39'>30-39</option>
                        <option value='40-49'>40-49</option>
                        <option value='50-59'>50-59</option>
                        <option value='60-69'>60-69</option>
                        <option value='≥70'>&#8805;70</option>
                      </select>
                    </div>

                    {/* <div className='mb-3 col-12'>
                      <input
                        className='form-control'
                        type='text'
                        name='line1'
                        value={newUser.address.line1}
                        onChange={handleStreetInput}
                        placeholder='📫 Street address'
                      />
                    </div>
                    <div className='mb-3 col-sm-6'>
                      <input
                        className='form-control'
                        type='text'
                        name='suburb'
                        value={newUser.address.suburb}
                        onChange={handleStreetInput}
                        placeholder='Suburb'
                      />
                    </div>
                    <div className='mb-3 col-6 col-sm-3'>
                      <input
                        className='form-control'
                        type='text'
                        name='state'
                        value={newUser.address.state}
                        onChange={handleStreetInput}
                        placeholder='State'
                      />
                    </div> */}
                    <div className='mb-3 col-sm-6'>
                      <input
                        className='form-control'
                        type='text'
                        name='postcode'
                        value={newUser.address.postcode}
                        onChange={handleStreetInput}
                        placeholder='Postcode'
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <Alert
              show={activating}
              variant='info'
              onClose={() => setActivating(false)}
              dismissible
            >
              <h5>Account Activation Required</h5>
              <p>
                An account activation email has been sent to you. Please check
                your email and follow the instructions to activate your account.
              </p>
              <p>
                Note: if you cannot find the activation email, please check your{' '}
                <span style={{ backgroundColor: 'yellow' }}>Spam Email</span> or{' '}
                <span style={{ backgroundColor: 'yellow' }}>Junk Email</span>{' '}
                folder.
              </p>
            </Alert>
          </Modal.Body>

          <Modal.Footer>
            {activating ? (
              <Link
                className='btn btn-info'
                to='/'
                onClick={() => setShowAuthModal(false)}
              >
                Return
              </Link>
            ) : (
              <div className='card-footer'>
                {isLoginMode ? (
                  <div className='row'>
                    <div className='col-12 text-center'>
                      <button className='btn btn-warning ml-auto' type='submit'>
                        <i className='fas fa-user-check'></i>
                        {langEn ? 'Login' : '登录'}
                      </button>
                    </div>
                    <div className='col-12 text-center p-3'>
                      <div
                        style={{
                          width: '100%',
                          height: '.8rem',
                          borderBottom: '1px solid black',
                          textAlign: 'center',
                        }}
                      >
                        <span
                          style={{
                            // fontSize: '1rem',
                            backgroundColor: '#F3F5F6',
                            padding: '0 10px',
                          }}
                        >
                          Alternatively, sign in with Google
                        </span>
                      </div>
                    </div>
                    <div className='col-12 text-center'>
                      <ReactGoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        // buttonText='Log in with Google'
                        onSuccess={handleGoogleLogin}
                        onFailure={handleGoogleLogin}
                        cookiePolicy={'single_host_origin'}
                      />
                    </div>
                    <Alert
                      show={!!errorState}
                      variant='info'
                      onClose={() => setErrorState(null)}
                      dismissible
                    >
                      <h5>Error: {errorState}</h5>
                      {errorState === 'wrong password' && (
                        <ForgotPassword
                          emailInput={newUser.email}
                          setShowAuthModal={setShowAuthModal}
                        />
                      )}
                      {errorState === 'user not found' && (
                        <p>
                          Please Register{' '}
                          <span role='img' aria-label='above'>
                            👆
                          </span>{' '}
                        </p>
                      )}
                    </Alert>
                  </div>
                ) : (
                  <button className='btn btn-primary' type='submit'>
                    <h5>
                      <i className='fas fa-user-plus'></i>
                      {langEn ? 'Register' : '注册'}
                    </h5>
                  </button>
                )}
              </div>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
