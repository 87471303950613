import React from 'react';

export default function SlideManageColumns() {
  return (
    <div className='bg-primary text-white font-weight-bold'>
      <div className='row'>
        <div className='col-11 col-lg-1'>name</div>
        <div className='col-11 col-lg-1'>名称</div>
        <div className='col-11 col-lg-1'>sequenceNr</div>
        <div className='col-11 col-lg-2'>image</div>
        <div className='col-11 col-lg-6'>link</div>

        <div className='col-11 col-lg-1'>save/edit</div>
      </div>
    </div>
  );
}
