import React, { useState, useContext } from 'react';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';

export default function SlideManageEdit({ index, slide, categories }) {
  const { token } = useContext(AuthContext);
  const [editSlide, setEditSlide] = useState(slide);

  const [slideImg, setSlideImg] = useState(null);
  const handleSelectedImage = (e) => {
    setSlideImg(e.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditSlide((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;
    const uploadSlide = new FormData();
    uploadSlide.append('slideImage', slideImg);
    // field name 'slideImage' must match backend post: upload.single('slideImage')

    Object.keys(editSlide).forEach(function (k) {
      uploadSlide.append(k, editSlide[k]);
    });

    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/slides/update/' + editSlide._id,
        uploadSlide,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'form row mt-2 bg-white' : 'form row mt-2'}>
        <div className='col-1'>
          <input
            required
            className='form-control'
            type='text'
            name='name'
            value={editSlide.name}
            onChange={handleChange}
            placeholder='name'
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            type='text'
            name='nameCN'
            value={editSlide.nameCN}
            onChange={handleChange}
            placeholder='名称'
          />
        </div>
        <div className='col-1'>
          <input
            required
            className='form-control'
            type='number'
            name='seqNr'
            value={editSlide.seqNr}
            onChange={handleChange}
            placeholder='sequenceNr'
          />
        </div>

        <div className='col-2'>
          <input
            className='form-control-file'
            type='file'
            name='image'
            onChange={handleSelectedImage}
          />
        </div>

        <div className='col-6'>
          <input
            className='form-control'
            type='text'
            name='link'
            value={editSlide.link}
            onChange={handleChange}
            placeholder='link'
          />
        </div>

        <div className='col-1'>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>
      </div>

      <div className='col-11'>
        <input
          className='form-control'
          type='text'
          name='note'
          value={editSlide.note}
          onChange={handleChange}
          placeholder='note'
        />
      </div>

      <hr />
    </form>
  );
}
