import React, { useContext } from 'react';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';

export default function ProductCategoryItem({ index, cat, setEdit }) {
  const { token } = useContext(AuthContext);

  async function deleteProd(productId) {
    const authStr = 'Bearer ' + token;

    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/productCats/delete/' + productId,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={index % 2 ? 'row mt-2 bg-white' : 'row mt-2'}>
        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>name: </span>
          <h3>{cat.name}</h3>
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>名称: </span>
          {cat.nameCN}
        </div>
        <div className='col-11 col-lg-3'>
          <span className='d-lg-none'>url: </span>
          {cat.url}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>catID: </span>
          {cat.catID}
        </div>

        <div className='col-11 col-lg-2'>
          <img
            src={process.env.REACT_APP_ASSET_URL + '/' + cat.image}
            style={{ width: '3rem', height: '2rem' }}
            className='img-fluid'
            alt='cat'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>active: </span>
          <input type='checkbox' checked={cat.active} disabled />
        </div>

        <div className='col-11 col-lg-1'>
          <button className='btn btn-link' onClick={() => setEdit(cat._id)}>
            <i className='fas fa-edit' />
          </button>
          {!cat.active && (
            <button
              className='btn btn-link'
              onClick={() => deleteProd(cat._id)}
            >
              <i className='fas fa-trash-alt' />
            </button>
          )}
        </div>
      </div>

      <div className={index % 2 ? 'row bg-white' : 'row'}>
        <div className='col-12'>
          <h5>Description:</h5>
        </div>
        <div className='col-1'>paragraph1:</div>
        <div className='col-11'>{cat.p1}</div>
        <div className='col-1'>paragraph2:</div>
        <div className='col-11'>{cat.p2}</div>
        <div className='col-1'>paragraph3:</div>
        <div className='col-11'>{cat.p3}</div>
        <div className='col-1'>paragraph4:</div>
        <div className='col-11'>{cat.p4}</div>
        <div className='col-1'>paragraph5:</div>
        <div className='col-11'>{cat.p5}</div>

        <div className='col-12'>
          <h5>Steeping (brewing) recomendations:</h5>
        </div>
        <div className='col-1'>Water:</div>
        <div className='col-11'>{cat.recWater}</div>
        <div className='col-1'>Temperature:</div>
        <div className='col-11'>{cat.recTemp}</div>
        <div className='col-1'>Time:</div>
        <div className='col-11'>{cat.recTime}</div>
        <div className='col-1'>Times of brewing:</div>
        <div className='col-11'>{cat.recTimes}</div>
        <hr />
      </div>
    </>
  );
}
