import React from 'react';

export default function UsersColumns() {
  return (
    <div className='row bg-primary text-white font-weight-bold'>
      <div className='col-1'>FirstName</div>
      <div className='col-1'>LastName</div>
      <div className='col-2'>email</div>
      <div className='col-1'>phone</div>
      <div className='col-1'>gender</div>
      <div className='col-1'>age</div>
      <div className='col-1'>activated</div>
      <div className='col-1'>admin</div>
      <div className='col-1'>membership</div>
      <div className='col-1'>orders</div>
      <div className='col-1'>edit</div>
    </div>
  );
}
