import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import NavFooter from './components/Navbar/NavFooter';
import Heritage from './components/Heritage/Heritage';
import ProductManage from './components/ProductManage/ProductManage';
import AboutUs from './components/AboutUs/AboutUs';
import Message from './components/AboutUs/Message';
import ProductProvider from './context/ProductProvider';
import AuthProvider from './context/AuthProvider';
import UserActivation from './components/User/UserActivation';
import UserUpdate from './components/User/UserUpdate';
import UserManage from './components/UserManage/UserManage';
import ResetPassword from './components/User/ResetPassword';
import ProductCategory from './components/ProductCategory/ProductCategory';
import ProductsByCat from './components/Product/ProductsByCat';
import CartProvider from './context/CartProvider';
import Cart from './components/Cart/Cart';
import OrderCheckout from './components/Order/OrderCheckout';
import MyOrders from './components/Order/MyOrders';
import OrderManage from './components/Order/OrderManage';
import ProductSearch from './components/Product/ProductSearch';
import SlideManage from './components/SlideManage/SlideManage';
import ProductCategoriesOurTeas from './components/Product/ProductCategoriesOurTeas';
import ProductDetails from './components/Product/ProductDetails';
import AdminRoute from './components/Routes/AdminRoute';
import PrivateRoute from './components/Routes/PrivateRoute';
import ProductsSales from './components/Product/ProductsSales';

function App() {
  return (
    <AuthProvider>
      <ProductProvider>
        <CartProvider>
          <Router>
            <Navbar />
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/heritage' component={Heritage} />
              <Route
                exact
                path='/productCategories'
                component={ProductCategoriesOurTeas}
              />
              <Route
                exact
                path='/productbyid/:productId'
                component={ProductDetails}
              />
              <Route exact path='/products/cats' component={ProductCategory} />
              <Route
                exact
                path='/productCategory/:cat'
                component={ProductsByCat}
              />
              <Route exact path='/products/sales' component={ProductsSales} />
              <Route exact path='/products/search' component={ProductSearch} />
              <Route exact path='/aboutus' component={AboutUs} />
              <Route exact path='/message' component={Message} />

              <Route exact path='/cart' component={Cart} />
              <Route exact path='/cartcheckout' component={OrderCheckout} />

              <Route
                exact
                path='/user/activate/:emailToken'
                component={UserActivation}
              />
              <Route
                exact
                path='/user/resetPassword/:emailToken'
                component={ResetPassword}
              />

              <PrivateRoute exact path='/user/update' component={UserUpdate} />
              <PrivateRoute exact path='/myorders' component={MyOrders} />

              <AdminRoute
                exact
                path='/products/manage'
                component={ProductManage}
              />
              <AdminRoute exact path='/orders' component={OrderManage} />
              <AdminRoute exact path='/slides/manage' component={SlideManage} />
              <AdminRoute exact path='/user/manage' component={UserManage} />
              <Redirect to='/' />
            </Switch>
            <NavFooter />
          </Router>
        </CartProvider>
      </ProductProvider>
    </AuthProvider>
  );
}

export default App;
