import React from 'react';

export default function ProductManageColumns() {
  return (
    <div className='bg-primary text-white font-weight-bold'>
      <div className='row'>
        <div className='col-11 col-lg-1'>name</div>
        <div className='col-11 col-lg-1'>名称</div>
        <div className='col-11 col-lg-1'>sequenceNr</div>
        <div className='col-11 col-lg-1'>Category</div>
        <div className='col-11 col-lg-2'>image</div>
        <div className='col-11 col-lg-1 text-end'>price</div>
        <div className='col-11 col-lg-1'>discount%</div>
        <div className='col-11 col-lg-1 text-center'>GST</div>
        <div className='col-11 col-lg-1'>unit</div>
        <div className='col-11 col-lg-1'>stock</div>
        <div className='col-11 col-lg-1 text-center'>onShelf</div>
      </div>
    </div>
  );
}
