import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';

import CartContext from '../../context/CartContext';
import AuthContext from '../../context/AuthContext';

export default function CartTotals() {
  const { cart, setCartCostTotal } = useContext(CartContext);
  const { membership } = useContext(AuthContext);
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [volumeDiscount, setVolumeDiscount] = useState(0);
  const [memberDiscount, setMemberDiscount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponDeduct, setCouponDeduct] = useState(0);
  const [couponCode, setCouponCode] = useState('');
  const [orderPostage, setOrderPostage] = useState(0);
  const [cartPriceTotal, setCartPriceTotal] = useState(0);
  const [cartGSTTotal, setCartGSTTotal] = useState(0);
  const [cartDiscountTotal, setCartDiscountTotal] = useState(0);

  useEffect(() => {
    const getPostage = async () => {
      try {
        const res = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/orders/orderPostage'
        );
        setOrderPostage(res.data.orderPostage);
      } catch (error) {
        console.error(error);
      }
    };
    getPostage();
  }, []);

  useEffect(() => {
    let _cartPriceTotal = 0;
    let _cartDiscountTotal = 0;
    let _cartGSTTotal = 0;

    // Volume Discount array
    // volume must be in ascending order
    const volumeDisc = [
      [100, 0],
      [200, 0],
      [300, 0],
    ];

    const memberDisc = {
      guest: 0.0,
      member: 0.0,
      member_google: 0.0,
      friend: 0.0,
      VIP: 0.0,
    };

    // set coupon expiry date to 30 days from now
    // let _expireDate = new Date();
    // console.log('today: ', _expireDate);
    // _expireDate.setDate(_expireDate.getDate() + 30);
    const _expireDate = new Date(2022, 0, 2); // note: month is from 0 to 11!
    console.log('Expire Date:', _expireDate);

    const couponDiscTable = [
      {
        code: 'abc123',
        isPercent: true,
        amount: 10,
        expire: _expireDate,
      },
      {
        code: 'def456',
        isPercent: false,
        amount: 5,
        expire: _expireDate,
      },
    ];

    // apply product discount
    for (const cartItem of cart) {
      _cartPriceTotal +=
        cartItem.price * (1 - cartItem.discount / 100.0) * cartItem.quantity;
      _cartGSTTotal +=
        (cartItem.includeGST
          ? (cartItem.price * (1 - cartItem.discount / 100.0)) / 11.0
          : 0) * cartItem.quantity;
      _cartDiscountTotal +=
        cartItem.price * (cartItem.discount / 100) * cartItem.quantity;
    }
    console.log('before discount', _cartPriceTotal);
    setCartSubTotal(_cartPriceTotal);

    // apply Volume discount, note volumeDis[i][0] must be in ascending order
    let _volDisc = 0;
    for (let i = 0; i < volumeDisc.length; i++) {
      if (_cartPriceTotal >= volumeDisc[i][0])
        _volDisc = volumeDisc[i][1] / 100.0;
      else break;
    }
    console.log('Volume discount: ', _volDisc);

    // apply Member discount
    let _memDisc = memberDisc[membership] / 100.0;
    console.log('member discount: ', _memDisc);

    // Coupon discount
    let _couponDisc = 0;
    let _couponDeduct = 0;
    const foundCoupon = couponDiscTable.find(
      (element) => element.code === couponCode
    );
    if (foundCoupon && foundCoupon.expire > Date.now()) {
      if (foundCoupon.isPercent) {
        _couponDisc = foundCoupon.amount / 100.0;
      } else {
        _couponDeduct = foundCoupon.amount;
      }
    }
    console.log('Coupon discount: ', _couponDisc);
    console.log('Coupon Deduct: ', _couponDeduct);

    // Combined discount
    const _combDisc = 1 - (1 - _volDisc) * (1 - _couponDisc) * (1 - _memDisc);
    console.log('combined discount: ', _combDisc);

    _cartDiscountTotal += _cartPriceTotal * _combDisc + _couponDeduct;
    _cartPriceTotal = _cartPriceTotal * (1 - _combDisc) - _couponDeduct;
    _cartGSTTotal = _cartGSTTotal * (1 - _combDisc) - _couponDeduct / 11.0;
    console.log(
      'Discount Total:$' +
        _cartDiscountTotal +
        '; Price Total:$' +
        _cartPriceTotal +
        '; GST Total:$' +
        _cartGSTTotal.toFixed(2)
    );

    setVolumeDiscount(_volDisc);
    setMemberDiscount(_memDisc);
    setCouponDiscount(_couponDisc);
    setCouponDeduct(_couponDeduct);
    setCartPriceTotal(_cartPriceTotal + orderPostage);
    setCartCostTotal(_cartPriceTotal + orderPostage); // updating checkout amount in Stripe button.
    setCartGSTTotal(_cartGSTTotal);
    // setCartGSTTotal(_cartGSTTotal + orderPostage / 11.0);
    setCartDiscountTotal(_cartDiscountTotal);
  }, [cart, orderPostage, membership, couponCode, setCartCostTotal]);

  const handleChange = (event) => {
    setCouponCode(event.target.value);
  };

  return (
    <div>
      {cartPriceTotal > orderPostage ? (
        <div className='row'>
          <hr />
          <div className='col-8 col-sm-10 text-end'>Subtotal:</div>
          <div className='col-4 col-sm-2 text-end'>
            ${cartSubTotal.toFixed(2)}
          </div>

          {volumeDiscount > 0 && (
            <>
              <div className='col-8 col-sm-10 text-end'>Volume Discount*:</div>
              <div className='col-4 col-sm-2 text-end'>
                {volumeDiscount * 100}%
              </div>
            </>
          )}

          {memberDiscount > 0 && (
            <>
              <div className='col-8 col-sm-10 text-end'>Member Discount*:</div>
              <div className='col-4 col-sm-2 text-end'>
                {memberDiscount * 100}%
              </div>
            </>
          )}

          {couponDiscount === 0 && couponDeduct === 0 ? (
            <>
              <label
                className='col-8 col-sm-10 text-end d-none'
                htmlFor='coupon-code'
              >
                Coupon Code*:
              </label>
              <div className='col-4 col-sm-2 text-end d-none'>
                <input
                  type='text'
                  className='form-control form-control-sm text-end'
                  id='coupon-code'
                  placeholder='Coupon Code'
                  name='couponCode'
                  onChange={handleChange}
                />
              </div>
            </>
          ) : (
            <>
              {couponDiscount > 0 && (
                <>
                  <div className='col-8 col-sm-10 text-end'>
                    Coupon Discount*:
                  </div>
                  <div className='col-4 col-sm-2 text-end'>
                    {couponDiscount * 100}%
                  </div>
                </>
              )}
              {couponDeduct > 0 && (
                <>
                  <div className='col-8 col-sm-10 text-end'>
                    Coupon Deduct*:
                  </div>
                  <div className='col-4 col-sm-2 text-end'>
                    -${couponDeduct}
                  </div>
                </>
              )}
            </>
          )}

          <div className='col-8 col-sm-10 text-end'>Postage:</div>
          <div className='col-4 col-sm-2 text-end'>
            ${orderPostage.toFixed(2)}
          </div>
          <div className='col-8 col-sm-10 text-end'>
            <b>Total:</b>
          </div>
          <div className='col-4 col-sm-2 text-end'>
            <b>${cartPriceTotal.toFixed(2)}</b>
          </div>
          {cartGSTTotal > 0 && (
            <>
              <div className='col-8 col-sm-10 text-end'>Incl. GST:</div>
              <div className='col-4 col-sm-2 text-end'>
                ${cartGSTTotal.toFixed(2)}
              </div>
            </>
          )}
          <div className='col-8 col-sm-10 text-end'>***************</div>
          <div className='col-4 col-sm-2 text-end'>**********</div>
          <div className='col-8 col-sm-10 text-end'>Total Savings*:</div>
          <div className='col-4 col-sm-2 text-end'>
            ${cartDiscountTotal.toFixed(2)}
          </div>
          <div className='col-8 col-sm-10 text-end'>***************</div>
          <div className='col-4 col-sm-2 text-end'>**********</div>
          <hr />
          <div className='col-8 col-sm-6 text-end'></div>
          <div className='col-4 col-sm-6 d-flex justify-content-end d-none'>
            <div>
              <h5>*Savings may include:</h5>
              <ul>
                <li>
                  <b>Product Discount:</b> applies to individual product
                </li>
                <li>
                  <b>Volume Discount:</b>
                  <ul>
                    <li>10% discount for an order exceeding $100</li>
                    <li>15% discount for an order exceeding $200</li>
                    <li>20% discount for an order exceeding $300</li>
                  </ul>
                </li>
                <li>
                  <b>Member Discount:</b>
                  <ul>
                    <li>Registered members receive 5% discount</li>
                    <li>True Tea friends receive 10% discount</li>
                    <li>VIP members receive 20% discount</li>
                  </ul>
                </li>
                <li>
                  <b>Coupon Discount:</b>
                  <ul>
                    <li>10% discount coupon: abc123</li>
                    <li>$5 off coupon: def456</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h5>Cart is empty</h5>
        </div>
      )}
    </div>
  );
}
