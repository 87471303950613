import { createContext } from 'react';

const AuthContext = createContext({
  langEn: true,
  setLang: () => {},
  token: null,
  userId: '',
  userName: '',
  admin: false,
  membership: '',
  login: () => {},
  logout: () => {},
});

export default AuthContext;
