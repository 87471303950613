import React from 'react';

import './Navbar.css';

export default function NavFooter() {
  const phantom = {
    display: 'block',
    padding: '10px',
    height: '60px',
    width: '100%',
  };

  return (
    <div>
      <div style={phantom} />
      <div className='nav-footer'>
        {/* <span id='span-left'>
          &emsp;
          <b>Get in touch: </b>&emsp;<i className='fas fa-phone-alt'></i>{' '}
          0417440452
        </span> */}
        <span>
          &copy; 2021{'  '}
          <a href='/' className='text-white'>
            <u>True Tea</u>
          </a>
          {/* , ABN 49 168 458 580 */}
        </span>
        <span id='span-right'>
          <b>Follow us: </b>&emsp;
          <a
            href='https://www.facebook.com/True-Tea-112850364506997'
            className='text-white'
          >
            <i className='fab fa-facebook-f' />
          </a>
          &emsp;<i className='fab fa-youtube'></i>
        </span>
      </div>
    </div>
  );
}
