import React, { useState, useContext, useEffect } from 'react';

import productContext from '../../context/ProductContext';
import ProductItem from './ProductItem';
import './Product.css';

export default function ProductSearch() {
  const { products } = useContext(productContext);

  const [filteredProducts, setFilteredProducts] = useState([]);
  // const [brands, setBrands] = useState([]);
  // const [openBrands, setOpenBrands] = useState(false);

  useEffect(() => {
    // setFilteredProducts(products);
    setFilteredProducts([]);

    // let _brands = [];
    // products.forEach((item) => {
    //   // find all defined brands without duplicate
    //   if (
    //     typeof item.brand !== 'undefined' &&
    //     item.brand !== null &&
    //     item.brand !== '' &&
    //     !_brands.some((e) => e.name === item.brand)
    //   ) {
    //     _brands.push({
    //       name: item.brand,
    //       cnName: item.cnBrand,
    //       isChecked: false,
    //     });
    //   }
    // });
    // _brands.sort((a, b) => {
    //   if (a.name.toLowerCase() >= b.name.toLowerCase()) {
    //     return 1;
    //   } else {
    //     return -1;
    //   }
    // });
    // setBrands([..._brands]);
  }, [products]);

  const handleSearch = (e) => {
    let currList = [];
    let newList = [];
    if (e.target.value !== '') {
      currList = products;
      newList = currList.filter((item) => {
        const lcName = item.name.toLowerCase();
        const lcRegion = item.region.toLowerCase();
        const lcYear = item.year.toLowerCase();
        const lcNote = item.note.toLowerCase();
        const searchStr = e.target.value.toLowerCase();
        const searchRes =
          lcName.includes(searchStr) ||
          lcRegion.includes(searchStr) ||
          lcYear.includes(searchStr) ||
          lcNote.includes(searchStr);
        return searchRes;
      });
    } else {
      // newList = products;
    }
    setFilteredProducts([...newList]);
  };

  // const handleCheck = useCallback(
  //   (e) => {
  //     const index = e.target.name;
  //     let _brandsTemp = [...brands];
  //     _brandsTemp[index].isChecked = e.target.checked;
  //     setBrands(_brandsTemp);

  //     let _checkedBrands = [];
  //     _brandsTemp.forEach((brand) => {
  //       if (brand.isChecked) {
  //         _checkedBrands.push(brand.name);
  //       }
  //     });
  //     let newList = [];
  //     if (_checkedBrands.length > 0) {
  //       newList = products.filter((item) => {
  //         return _checkedBrands.includes(item.brand);
  //       });
  //     } else {
  //       // newList = [...products];
  //     }
  //     setFilteredProducts([...newList]);
  //   },
  //   [brands, products]
  // );

  return (
    <div className='container p-3'>
      <div className='col-11 col-lg-1'>
        <input type='text' onChange={handleSearch} placeholder='🔍Search...' />

        {/* <button
            className='btn btn-link px-1'
            onClick={() => setOpenBrands(!openBrands)}
            aria-controls='brands'
            aria-expanded={openBrands}
          >
            <h5>▼{langEn ? 'Brands' : '品牌'}</h5>
          </button>
          <Collapse in={openBrands}>
            <div id='brands'>
              {brands.map((item, index) => {
                return (
                  <div className='form-check' key={index}>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      key={index}
                      name={index}
                      checked={item.isChecked}
                      onChange={handleCheck}
                      id='defaultCheck1'
                    />
                    <label className='form-check-label' htmlFor='defaultCheck1'>
                      {langEn ? item.name : item.cnName}
                    </label>
                  </div>
                );
              })}
            </div>
          </Collapse> */}
      </div>
      {filteredProducts.length < 1 && (
        <h3>
          <span role='img' aria-label='search'>
            Search Products
          </span>
        </h3>
      )}
      <div className='row row-cols-2 row-cols-md-3 row-cols-lg-4 g-1'>
        {filteredProducts.map((product) => {
          return <ProductItem key={product._id} product={product} />;
        })}
      </div>
    </div>
  );
}
