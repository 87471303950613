import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';

export default function ProductDetailsImages({ productImages }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Carousel
        interval={null}
        variant='dark'
        activeIndex={index}
        onSelect={handleSelect}
      >
        {productImages.map((img, index) => {
          return (
            <Carousel.Item key={index}>
              <img
                className='d-block w-100 p-5 pt-2'
                src={process.env.REACT_APP_ASSET_URL + '/' + img}
                alt='First slide'
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div className='container row row-cols-6 mx-0 p-0'>
        {productImages.map((img, index) => {
          return (
            <div className='col p-1' key={index}>
              <img
                className='d-block w-100'
                src={process.env.REACT_APP_ASSET_URL + '/' + img}
                alt='Third slide'
                onClick={() => {
                  setIndex(index);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
