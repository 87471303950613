import React, { useState, useContext } from 'react';

import ProductManageColumns from './ProductManageColumns';
import ProductManageItem from './ProductManageItem';
import ProductManageAdd from './ProductManageAdd';
import ProductManageEdit from './ProductManageEdit';
import ProductContext from '../../context/ProductContext';

export default function ProductManage() {
  const { productsAll, categories } = useContext(ProductContext);

  const [editProductId, setEditProductId] = useState('');
  const setEdit = (productId) => {
    setEditProductId(productId);
  };

  return (
    <div className='container-fluid bg-light text-dark'>
      <h1 className='text-center'>Manage Products</h1>
      <ProductManageColumns />
      <h5>Add a New Product:</h5>
      <ProductManageAdd categories={categories} />
      <h5>Product List:</h5>
      {productsAll.map((product, index) => {
        if (product._id === editProductId) {
          return (
            <ProductManageEdit
              key={product._id}
              index={index}
              product={product}
              categories={categories}
            />
          );
        } else {
          return (
            <ProductManageItem
              key={product._id}
              index={index}
              product={product}
              setEdit={setEdit}
              cat={categories.find((item) => item._id === product.categoryId)}
            />
          );
        }
      })}
    </div>
  );
}
