import React, { useState, useContext } from 'react';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';

function ProductManageAdd({ categories }) {
  const initProduct = {
    name: '',
    nameCN: '',
    seqNr: '',
    // brand: '',
    // brandCN: '',
    categoryId: '',
    price: '',
    discount: 0,
    includeGST: false,
    stock: 100,
    onShelf: true,
    unit: '',
    region: '',
    year: '',
    note: '',
  };

  const { token } = useContext(AuthContext);
  const [newProduct, setNewProduct] = useState(initProduct);

  const [productImgs, setProductImgs] = useState([]);
  const handleSelectedImage = (e) => {
    let fileArray = [];
    for (let i = 0; i < e.target.files.length; i++) {
      fileArray.push(e.target.files[i]);
    }
    setProductImgs(fileArray);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setNewProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const authStr = 'Bearer ' + token;

    const uploadProduct = new FormData();
    productImgs.map((productImg) => {
      return uploadProduct.append('productImages', productImg);
    });
    // field name 'productImage' must match backend post: upload.single('productImage')

    Object.keys(newProduct).forEach(function (k) {
      uploadProduct.append(k, newProduct[k]);
    });

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/products/add',
        uploadProduct,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    setNewProduct(initProduct);
    setProductImgs(null);
    window.location.reload();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='form row mt-2 bg-white'>
        <div className='col-1'>
          <input
            className='form-control'
            required
            type='text'
            name='name'
            value={newProduct.name}
            onChange={handleChange}
            placeholder='name'
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            type='text'
            name='nameCN'
            value={newProduct.nameCN}
            onChange={handleChange}
            placeholder='名称'
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            required
            type='number'
            name='seqNr'
            value={newProduct.seqNr}
            onChange={handleChange}
            placeholder='sequenceNr'
          />
        </div>
        <div className='col-1'>
          <select
            className='form-select'
            defaultValue=''
            name='categoryId'
            onChange={handleChange}
          >
            <option disabled value=''>
              select categories
            </option>
            {categories.map((item) => {
              return (
                <option key={item.name} value={item._id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className='col-2'>
          <input
            className='form-control-file'
            // required
            type='file'
            name='images'
            required
            multiple
            onChange={handleSelectedImage}
          />
        </div>
        <div className='col-1'>
          <div className='input-group'>
            <span className='input-group-text'>$</span>
            <input
              className='form-control text-end'
              type='number'
              name='price'
              value={newProduct.price}
              onChange={handleChange}
              placeholder='price'
            />
          </div>
        </div>
        <div className='col-1'>
          <div className='input-group'>
            <input
              className='form-control text-end'
              type='number'
              name='discount'
              value={newProduct.discount}
              onChange={handleChange}
              placeholder='discount'
            />
            <span className='input-group-text'>%</span>
          </div>
        </div>
        <div className='col-1 text-center'>
          <input
            className='form-check-input'
            type='checkbox'
            name='includeGST'
            checked={newProduct.includeGST}
            onChange={handleCheck}
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            name='unit'
            type='text'
            value={newProduct.unit}
            onChange={handleChange}
            placeholder='unit'
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            type='number'
            name='stock'
            value={newProduct.stock}
            onChange={handleChange}
            placeholder='stock'
          />
        </div>
        <div className='col-1 text-center'>
          <input
            className='form-check-input'
            type='checkbox'
            name='onShelf'
            checked={newProduct.onShelf}
            onChange={handleCheck}
          />
        </div>
      </div>

      <div className='form row bg-white'>
        <div className='col-2'>
          <input
            className='form-control'
            name='region'
            type='text'
            value={newProduct.region}
            onChange={handleChange}
            placeholder='Region'
          />
        </div>
        <div className='col-1'>
          <input
            className='form-control'
            name='year'
            type='text'
            value={newProduct.year}
            onChange={handleChange}
            placeholder='Year'
          />
        </div>
        <div className='col-8'>
          <textarea
            className='form-control'
            rows='3'
            name='note'
            value={newProduct.note}
            onChange={handleChange}
            placeholder='note'
          />
        </div>
        <div className='col-1 text-center'>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>
        <hr />
      </div>
    </form>
  );
}
export default ProductManageAdd;
