import React, { useContext } from 'react';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';

export default function ProductManageItem({ index, product, setEdit, cat }) {
  const { token } = useContext(AuthContext);

  async function deleteProd(productId) {
    const authStr = 'Bearer ' + token;

    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/products/delete/' + productId,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={index % 2 ? 'row mt-2 bg-white' : 'row mt-2'}>
        <div className='col-1'>{product.name}</div>
        <div className='col-1'>{product.nameCN}</div>
        <div className='col-1'>{product.seqNr}</div>
        <div className='col-1'>{cat && cat.name}</div>

        <div className='col-2'>
          <img
            src={process.env.REACT_APP_ASSET_URL + '/' + product.images[0]}
            style={{ width: '3rem', height: '2rem' }}
            className='img-fluid'
            alt='product'
          />
        </div>
        <div className='col-1 text-end'>${product.price}</div>
        <div className='col-1 text-end'>{product.discount}%</div>
        <div className='col-1 text-center'>
          <input type='checkbox' checked={product.includeGST} disabled />
        </div>
        <div className='col-1'>{product.unit}</div>
        <div className='col-1'>{product.stock}</div>
        <div className='col-1 text-center'>
          <input type='checkbox' checked={product.onShelf} disabled />
        </div>
      </div>

      <div className={index % 2 ? 'row bg-white' : 'row'}>
        {/* <div className='col-11  col-lg-1'>{product.brand}</div>
        <div className='col-11  col-lg-1'>{product.brandCN}</div> */}
        <div className='col-2'>{product.region}</div>
        <div className='col-1'>{product.year}</div>
        <div className='col-8'>{product.note}</div>
        <div className='col-1 text-center'>
          <button className='btn btn-link' onClick={() => setEdit(product._id)}>
            <i className='fas fa-edit' />
          </button>
          {!product.onShelf && (
            <button
              className='btn btn-link'
              onClick={() => deleteProd(product._id)}
            >
              <i className='fas fa-trash-alt' />
            </button>
          )}
        </div>{' '}
        <hr />
      </div>
    </>
  );
}
