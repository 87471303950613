import React, { useContext } from 'react';

import './Product.css';
import ProductContext from '../../context/ProductContext';
import ProductItem from './ProductItem';

export default function ProductCategories() {
  const { products, categories } = useContext(ProductContext);

  return (
    <div className='container-md'>
      {categories.map((cat, index) => {
        return (
          <section key={index}>
            <p></p>
            <h5 style={{ display: 'inline-block' }}>
              {cat.name} ({cat.nameCN})
            </h5>{' '}
            <small style={{ fontWeight: '300' }}>
              <a href={`/productCategory/${cat.name.replace(/\s/g, '')}`}>
                read more on Tea Category
              </a>
            </small>
            <div className='row row-cols-2 row-cols-md-3 row-cols-lg-4 g-1'>
              {products
                .filter((item) => item.categoryId === cat._id && item.onShelf)
                .map((product, index) => {
                  return <ProductItem product={product} key={index} />;
                })}
            </div>
          </section>
        );
      })}
    </div>
  );
}
