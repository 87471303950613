import React, { useContext } from 'react';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';
import OrdersByUserModal from '../Order/OrdersByUserModal';

export default function UsersItem({ index, user, setEdit }) {
  const { token } = useContext(AuthContext);

  async function deleteUser(_id) {
    const authStr = 'Bearer ' + token;
    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/users/deleteUserById/' + _id,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={index % 2 ? 'mt-1' : 'mt-1 bg-white'}>
        <div className='row'>
          <div className='col-1'>{user.fname}</div>
          <div className='col-1'>{user.lname}</div>
          <div className='col-2'>{user.email}</div>
          <div className='col-1'>{user.phone}</div>
          <div className='col-1'>{user.gender}</div>
          <div className='col-1'>{user.age}</div>
          <div className='col-1'>
            <input type='checkbox' checked={user.activated} disabled />
          </div>
          <div className='col-1'>
            <input type='checkbox' checked={user.admin} disabled />
          </div>
          <div className='col-1'>{user.membership}</div>
          <div className='col-1'>
            <OrdersByUserModal userId={user._id} token={token} />
          </div>
          <div className='col-1'>
            <div className='btn-group' role='group' aria-label='Basic example'>
              <button
                className='btn btn-link'
                onClick={() => setEdit(user._id)}
              >
                <i className='fas fa-edit' />
              </button>
              {!user.admin && !user.activated && (
                <>
                  |
                  <button
                    className='btn btn-link'
                    onClick={() => deleteUser(user._id)}
                    // disabled
                  >
                    <i className='fas fa-trash-alt' />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-1'>Address:</div>
          <div className='col-2'>{user.address.line1}</div>
          <div className='col-2'>{user.address.suburb}</div>
          <div className='col-1'>
            {user.address.state} {user.address.postcode}
          </div>
        </div>
      </div>
    </>
  );
}
