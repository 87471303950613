import { createContext } from 'react';

const ProductContext = createContext({
  products: [],
  productsAll: [],
  categories: [],
  categoriesAll: [],
  isLoading: false,
});

export default ProductContext;
