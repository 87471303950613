import React, { useState, useEffect, useContext } from 'react';

import CartContext from './CartContext';
import ProductContext from './ProductContext';

export default function CartProvider(props) {
  const { products } = useContext(ProductContext);
  const [cart, setCart] = useState([]);
  const [cartCostTotal, setCartCostTotal] = useState(0);

  useEffect(() => {
    // each time products are updated or the user comes back after a long time,
    // the cart contents need to be updated to reflect product changes
    // in price, stock, or onShelf status.
    let updatedCart = [];
    const updateCart = async () => {
      try {
        const _cart = JSON.parse(
          localStorage.getItem(process.env.REACT_APP_LS_CART)
        );

        if (_cart && _cart.length > 0) {
          _cart.forEach((cartItem) => {
            const foundProduct = products.find(
              (product) => product._id === cartItem._id
            );
            if (foundProduct) {
              // only add the product if it is still in product list
              if (foundProduct.onShelf && foundProduct.stock > 0) {
                // only add the product if it is still onShelf and have stock
                const newQuantity =
                  foundProduct.stock >= cartItem.quantity
                    ? cartItem.quantity
                    : foundProduct.stock;
                updatedCart.push({
                  ...foundProduct,
                  quantity: newQuantity,
                });
              }
            }
          });
        }

        setCart([...updatedCart]);
        localStorage.setItem(
          process.env.REACT_APP_LS_CART,
          JSON.stringify(updatedCart)
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (products.length > 0) {
      updateCart();
    }
  }, [products]);

  const addToCart = (_productId) => {
    const _cart = [...cart];
    const pIndex = _cart.findIndex((item) => item._id === _productId);
    if (pIndex >= 0) {
      // productId is already in cart list, add one more.
      _cart[pIndex].quantity++;
    } else {
      // this product is not in the cart list, so add a new product to cart
      const productToBeAdded = products.find((item) => item._id === _productId);
      _cart.push({
        ...productToBeAdded,
        quantity: 1,
      });
    }

    setCart([..._cart]);
    localStorage.setItem(process.env.REACT_APP_LS_CART, JSON.stringify(_cart));
  };

  const removeFromCart = (_productId) => {
    // remove one product: quantity--
    const _cart = [...cart];
    const pIndex = _cart.findIndex((item) => item._id === _productId);
    if (pIndex >= 0) {
      if (_cart[pIndex].quantity > 1) {
        _cart[pIndex].quantity--;
      } else {
        _cart.splice(pIndex, 1);
      }
    }

    setCart([..._cart]);
    localStorage.setItem(process.env.REACT_APP_LS_CART, JSON.stringify(_cart));
  };

  const deleteProductFromCart = (_productId) => {
    // delete the entire product from cart regardless its quantity
    const _cart = [...cart];
    const pIndex = _cart.findIndex((item) => item._id === _productId);
    if (pIndex >= 0) {
      _cart.splice(pIndex, 1);
    }

    setCart([..._cart]);
    localStorage.setItem(process.env.REACT_APP_LS_CART, JSON.stringify(_cart));
  };

  const clearCart = () => {
    // empty the entire cart
    setCart([]);
    localStorage.removeItem(process.env.REACT_APP_LS_CART);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        cartCostTotal,
        setCartCostTotal,

        addToCart,
        removeFromCart,
        deleteProductFromCart,
        clearCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}
