import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

import CartContext from '../../context/CartContext';
import OrderItem from './OrderItem';
import './Order.css';

export default function OrderCheckoutGuestForm() {
  const { cart, cartCostTotal, clearCart } = useContext(CartContext);
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [confirmedOrder, setConfirmedOrder] = useState();

  const [guest, setGuest] = useState({
    fname: '',
    lname: '',
    gender: '',
    age: '',
    email: '',
    password: '',
    password2: '',
    phone: '',
    address: { line1: '', line2: '', suburb: '', state: '', postcode: '' },
  });
  const [sameAsGuest, setSameAsGuest] = useState(false);
  const [shipping, setShipping] = useState({
    fname: '',
    lname: '',
    gender: '',
    age: '',
    email: '',
    password: '',
    password2: '',
    phone: '',
    address: { line1: '', line2: '', suburb: '', state: '', postcode: '' },
  });

  const stripe = useStripe();
  const elements = useElements();

  const stripeSubmit = async (event) => {
    event.preventDefault();

    const billingDetails = {
      name: guest.fname + ' ' + guest.lname,
      email: guest.email,
      phone: guest.phone,
      address: {
        line1: guest.address.line1,
        city: guest.address.suburb,
        state: guest.address.state,
        postal_code: guest.address.postcode,
        country: 'AU',
      },
    };

    setProcessingTo(true);

    try {
      const { data: client_secret } = await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/orders/stripepayGuest',
        { cart, billingDetails }
      );

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const paymentConfirm = await stripe.confirmCardPayment(client_secret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (paymentConfirm.error) {
        setCheckoutError(paymentConfirm.error.message);
        setProcessingTo(false);
        return;
      }

      // console.log('SUCCESS!', paymentConfirm.paymentIntent.payment_method);
      const { data: confirmedOrder } = await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/orders/addGuestOrder',
        {
          cart,
          paymentMethodReq,
          paymentConfirm,
          guest,
          shipping: sameAsGuest ? guest : shipping,
        }
      );
      setConfirmedOrder(confirmedOrder);
      clearCart();
      setProcessingTo(false);
    } catch (error) {
      setCheckoutError(error.message);
    }
  };

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const iframeStyles = {
    base: {
      color: 'white',
      backgroundColor: '#6772e5',
      fontSize: '15px',
      iconColor: '#fff',
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
    complete: {
      iconColor: '#cbf4c9',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    padding: '15px',
    hidePostalCode: true,
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setGuest((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleStreetInput = (event) => {
    const { name, value } = event.target;

    setGuest((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  const handleShippingChange = (event) => {
    const { name, value } = event.target;
    setShipping((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleShippingStreetInput = (event) => {
    const { name, value } = event.target;

    setShipping((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  return (
    <>
      {!confirmedOrder && guest && (
        <>
          {/* <OrderItem title={<h5>Cart Summary</h5>} order={cartOrder} /> */}

          <div className='card card-stripe m-2'>
            <div className='card-header' style={{ color: '#fff' }}>
              <h5>Payment Details:</h5>
            </div>

            <div>
              <form onSubmit={stripeSubmit}>
                <div className='card-stripe-element'>
                  <h5>Billing Details:</h5>
                  <div className='row g-1'>
                    <div className='col-6'>
                      <input
                        type='text'
                        className='form-control'
                        name='fname'
                        required
                        value={guest.fname}
                        onChange={handleChange}
                        placeholder='👤 first name'
                      />
                    </div>
                    <div className='col-6'>
                      <input
                        type='text'
                        className='form-control'
                        name='lname'
                        required
                        value={guest.lname}
                        onChange={handleChange}
                        placeholder='last name 👤'
                      />
                    </div>

                    <div className='col-6'>
                      <input
                        type='email'
                        required
                        className='form-control'
                        name='email'
                        value={guest.email}
                        onChange={handleChange}
                        placeholder='📧 email@domain'
                      />
                    </div>
                    <div className='col-6'>
                      <input
                        className='form-control'
                        type='text'
                        name='phone'
                        required
                        value={guest.phone}
                        pattern='^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$'
                        onChange={handleChange}
                        placeholder='☎ Mobile Number'
                      />
                    </div>

                    <div className='col-12'>
                      <input
                        className='form-control'
                        type='text'
                        name='line1'
                        required
                        value={guest.address.line1}
                        onChange={handleStreetInput}
                        placeholder='📫 Street address'
                      />
                    </div>
                    <div className='col-6'>
                      <input
                        className='form-control'
                        type='text'
                        name='suburb'
                        required
                        value={guest.address.suburb}
                        onChange={handleStreetInput}
                        placeholder='Suburb'
                      />
                    </div>
                    <div className='col-3'>
                      <input
                        className='form-control'
                        type='text'
                        name='state'
                        value={guest.address.state}
                        onChange={handleStreetInput}
                        placeholder='State'
                      />
                    </div>
                    <div className='col-3'>
                      <input
                        className='form-control'
                        type='text'
                        name='postcode'
                        value={guest.address.postcode}
                        onChange={handleStreetInput}
                        placeholder='pcode'
                      />
                    </div>
                  </div>
                </div>

                <div className='card-stripe-element'>
                  <h5 style={{ display: 'inline-block' }}>Deliver to:</h5>{' '}
                  <b>
                    (
                    <div
                      className='form-check'
                      style={{ display: 'inline-block' }}
                    >
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='sameAsGuest'
                        checked={sameAsGuest}
                        onChange={(event) => {
                          setSameAsGuest(event.target.checked);
                          setShipping(guest);
                        }}
                      />
                      <label className='form-check-label' htmlFor='sameAsGuest'>
                        same as Billing
                      </label>
                    </div>
                    )
                  </b>
                  {!sameAsGuest && (
                    <div className='row g-1'>
                      <div className='col-6'>
                        <input
                          type='text'
                          className='form-control'
                          name='fname'
                          required
                          value={shipping.fname}
                          onChange={handleShippingChange}
                          placeholder='👤 first name'
                        />
                      </div>
                      <div className='col-6'>
                        <input
                          type='text'
                          className='form-control'
                          name='lname'
                          required
                          value={shipping.lname}
                          onChange={handleShippingChange}
                          placeholder='last name 👤'
                        />
                      </div>

                      <div className='col-6'>
                        <input
                          type='email'
                          required
                          className='form-control'
                          name='email'
                          value={shipping.email}
                          onChange={handleShippingChange}
                          placeholder='📧 email@domain'
                        />
                      </div>
                      <div className='col-6'>
                        <input
                          className='form-control'
                          type='text'
                          name='phone'
                          required
                          value={shipping.phone}
                          pattern='^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$'
                          onChange={handleShippingChange}
                          placeholder='☎ Mobile Number'
                        />
                      </div>

                      <div className='col-12'>
                        <input
                          className='form-control'
                          type='text'
                          name='line1'
                          required
                          value={shipping.address.line1}
                          onChange={handleShippingStreetInput}
                          placeholder='📫 Street address'
                        />
                      </div>
                      <div className='col-6'>
                        <input
                          className='form-control'
                          type='text'
                          name='suburb'
                          required
                          value={shipping.address.suburb}
                          onChange={handleShippingStreetInput}
                          placeholder='Suburb'
                        />
                      </div>
                      <div className='col-3'>
                        <input
                          className='form-control'
                          type='text'
                          name='state'
                          value={shipping.address.state}
                          onChange={handleShippingStreetInput}
                          placeholder='State'
                        />
                      </div>
                      <div className='col-3'>
                        <input
                          className='form-control'
                          type='text'
                          name='postcode'
                          value={shipping.address.postcode}
                          onChange={handleShippingStreetInput}
                          placeholder='pcode'
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className='card-stripe-element'>
                  <h5>Card Details:</h5>
                  <CardElement
                    options={cardElementOpts}
                    onChange={handleCardDetailsChange}
                  />

                  <div className='my-2'>
                    <a
                      href='https://stripe.com/docs/currencies'
                      target='_blank'
                      rel='noopener noreferrer'
                      title='Users in Australia can accept Visa Mastercard American Express JCB credit and debit cards.'
                    >
                      <img
                        src={'/img/CreditCardImg/cc_visa.png'}
                        alt='visa'
                        style={{ height: '20px' }}
                      />{' '}
                      <img
                        src={'/img/CreditCardImg/cc_master.png'}
                        alt='master'
                        style={{ height: '20px' }}
                      />{' '}
                      <img
                        src={'/img/CreditCardImg/cc_american.png'}
                        alt='american'
                        style={{ height: '20px' }}
                      />{' '}
                      <img
                        src={'/img/CreditCardImg/cc_jcb.png'}
                        alt='jcb'
                        style={{ height: '20px' }}
                      />
                      <span style={{ color: '#87bbfd' }}>
                        {' '}
                        Accept major cards
                      </span>
                    </a>
                  </div>
                </div>

                <div className='card-stripe-element'>
                  <div className='my-1'>
                    {checkoutError && <h3>{checkoutError}</h3>}
                    <div className='my-1 text-center'>
                      <button
                        className='btn btn-light btn-stripe'
                        type='submit'
                        disabled={isProcessing || !stripe}
                        title='Your payment is secured by stripe.com'
                      >
                        <i className='fas fa-lock'></i>
                        {isProcessing
                          ? ' Processing... '
                          : ` *Pay: $${cartCostTotal.toFixed(2)} `}
                      </button>
                      <br />
                      *powered by{' '}
                      <b>
                        <a
                          href='https://stripe.com/au'
                          className='text-white'
                          target='_blank'
                          rel='noreferrer'
                        >
                          Stripe Payment
                        </a>
                      </b>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <small>
            *The credit card information and payment are processed by
            <br />
            <a href='https://stripe.com/au' target='_blank' rel='noreferrer'>
              Stripe Payment
            </a>
            , we do not keep your credit card details.
          </small>
        </>
      )}

      {confirmedOrder && guest && (
        <>
          <h1>Payment Confirmation</h1>
          <OrderItem order={confirmedOrder} />

          <Link to='/'>
            <button className='btn btn-outline-light btn-primary m-2'>
              <i className='fas fa-store'></i>
              Back to Shop
            </button>
          </Link>
        </>
      )}
    </>
  );
}
