import React, { useState, useEffect, useCallback } from 'react';

import AuthContext from './AuthContext';

var logoutTimer;
const LOGIN_TOKEN_DURATION = 3600 * 1000 * 10; // 10 hours

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [tokenExpireDate, setTokenExpireDate] = useState();
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [admin, setAdmin] = useState(false);
  const [membership, setMembership] = useState('guest');

  const [langEn, setLangEn] = useState(true);

  const setLang = () => {
    localStorage.setItem(process.env.REACT_APP_LS_LANGEN, !langEn);
    setLangEn(!langEn);
  };

  useEffect(() => {
    const _lang = localStorage.getItem(process.env.REACT_APP_LS_LANGEN);
    _lang && setLangEn(_lang === 'true');
  }, []);

  const login = useCallback(
    (_token, _uId, _uName, _admin, _membership, _expDate) => {
      setToken(_token);
      setUserId(_uId);
      setUserName(_uName);
      setAdmin(_admin);
      setMembership(_membership);
      const _tokenExpDate =
        _expDate || new Date(new Date().getTime() + LOGIN_TOKEN_DURATION);
      setTokenExpireDate(_tokenExpDate);
      // if login first time (_expDate NOT provided), _tokenExpDate is set to new Date()+1hr
      // if login from useEffect reload (_expDate provided), tokenExpDate is set to _expDate.

      localStorage.setItem(
        process.env.REACT_APP_LS_TOKEN,
        JSON.stringify({
          userId: _uId,
          token: _token,
          userName: _uName,
          admin: _admin,
          membership: _membership,
          expiration: _tokenExpDate.toISOString(),
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpireDate(null);
    setUserId(null);
    localStorage.removeItem(process.env.REACT_APP_LS_TOKEN);
  }, []);

  useEffect(() => {
    if (token && tokenExpireDate) {
      const remainingTime = tokenExpireDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      // logout();
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpireDate]);

  useEffect(() => {
    const storedData = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LS_TOKEN)
    );
    if (storedData && storedData.token) {
      if (new Date(storedData.expiration) > new Date()) {
        login(
          storedData.token,
          storedData.userId,
          storedData.userName,
          storedData.admin,
          storedData.membership,
          new Date(storedData.expiration)
        );
      } else {
        logout();
      }
    } else {
      logout();
    }
  }, [login, logout]);

  return (
    <AuthContext.Provider
      value={{
        langEn,
        setLang,
        token,
        userId,
        userName,
        admin,
        membership,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
