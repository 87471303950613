import React, { useContext } from 'react';

import CartItem from './CartItem';
import CartTotals from './CartTotals';
import CartContext from '../../context/CartContext';
import CartCheckout from './CartCheckout';

function Cart() {
  const { cart, orderPostage } = useContext(CartContext);

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-8'>
          <div>
            <h1>Cart</h1>
            {cart &&
              cart.map((item) => {
                return <CartItem key={item._id} cartItem={item} />;
              })}
            <CartTotals cart={cart} orderPostage={orderPostage} />
          </div>
        </div>
        <div className='col-lg-4 mt-3'>
          <CartCheckout />
        </div>
      </div>
    </div>
  );
}

export default Cart;
