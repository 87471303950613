import React, { useState, useContext } from 'react';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';

export default function UsersEdit({ index, user }) {
  const { token } = useContext(AuthContext);
  const [editUser, setEditUser] = useState(user);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditUser((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setEditUser((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  const handleAddressInput = (event) => {
    const { name, value } = event.target;

    setEditUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;
    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/users/updateUserById/' + user._id,
        editUser,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'mt-1' : 'mt-1 bg-white'}>
        <div className='row'>
          <div className='col-1'>{user.fname}</div>
          <div className='col-1'>{user.lname}</div>
          <div className='col-2'>{user.email}</div>
          <div className='col-1'>{user.phone}</div>
          <div className='col-1'>{user.gender}</div>
          <div className='col-1'>{user.age}</div>
          <div className='form-check col-1'>
            <input
              type='checkbox'
              className='form-check-input'
              name='activated'
              checked={editUser.activated}
              onChange={handleCheck}
            />
          </div>
          <div className='col-1 form-check'>
            <input
              type='checkbox'
              className='form-check-input'
              name='admin'
              checked={editUser.admin}
              onChange={handleCheck}
            />
          </div>

          <div className='col-2'>
            <select
              className='form-select'
              defaultValue=''
              name='membership'
              value={user.membership}
              onChange={handleChange}
            >
              <option disabled value=''>
                membership
              </option>
              <option value='member'>member</option>
              <option value='friend'>friend</option>
              <option value='VIP'>VIP</option>
            </select>
          </div>

          <div className='col-1'>
            <button className='btn btn-link' type='submit'>
              <i className='fas fa-save' />
            </button>
          </div>
        </div>
        <div className='row'>
          <label htmlFor='addressInput' className='col-1 col-form-label'>
            Address:
          </label>
          <div className='col-2'>
            <input
              type='text'
              className='form-control'
              name='line1'
              value={editUser.address.line1}
              onChange={handleAddressInput}
            />
          </div>
          <div className='col-2'>
            <input
              type='text'
              className='form-control'
              name='suburb'
              value={editUser.address.suburb}
              onChange={handleAddressInput}
            />
          </div>
          <div className='col-1'>
            <input
              type='text'
              className='form-control'
              name='state'
              value={editUser.address.state}
              onChange={handleAddressInput}
            />
          </div>
          <div className='col-1'>
            <input
              type='text'
              className='form-control'
              name='state'
              value={editUser.address.postcode}
              onChange={handleAddressInput}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
