import React, { useState, useContext } from 'react';
import axios from 'axios';

import AuthContext from '../../context/AuthContext';
// import ProductContext from '../../context/ProductContext';

export default function ProductCategoryEdit({ index, cat }) {
  const { token } = useContext(AuthContext);
  const [editCat, setEditCat] = useState(cat);

  const [catImg, setCatImg] = useState(null);
  const handleSelectedImage = (e) => {
    setCatImg(e.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditCat((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setEditCat((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;
    const uploadProduct = new FormData();
    uploadProduct.append('catImage', catImg);
    // field name 'catImage' must match backend post: upload.single('catImage')

    Object.keys(editCat).forEach(function (k) {
      uploadProduct.append(k, editCat[k]);
    });

    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL +
          '/productCats/update/' +
          editCat._id,
        uploadProduct,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'row mt-2 bg-white' : 'row mt-2'}>
        <div className='col-2'>
          <input
            required
            type='text'
            className='form-control'
            name='name'
            value={editCat.name}
            onChange={handleChange}
            placeholder='name'
          />
        </div>
        <div className='col-1'>
          <input
            type='text'
            className='form-control'
            name='nameCN'
            value={editCat.nameCN}
            onChange={handleChange}
            placeholder='名称'
          />
        </div>
        <div className='col-3'>
          <span className='d-lg-none'>url: </span>
          <input
            type='text'
            className='form-control'
            name='url'
            value={editCat.url}
            onChange={handleChange}
            placeholder='https://en.wikipedia.org/wiki/Tea'
          />
        </div>
        <div className='col-1'>
          <span className='d-lg-none'>id: </span>
          <input
            // required
            type='number'
            className='form-control'
            name='catID'
            value={editCat.catID}
            onChange={handleChange}
            placeholder='catID'
          />
        </div>
        <div className='col-2 '>
          <input
            // required
            type='file'
            className='form-control-file'
            name='image'
            onChange={handleSelectedImage}
          />
        </div>
        <div className='col-1'>
          <input
            type='checkbox'
            className='form-control-check'
            name='active'
            checked={editCat.active}
            onChange={handleCheck}
          />
        </div>

        <div className='col-11 col-lg-1'>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>

        <div className='col-12'>
          <h5>Description</h5>
        </div>
        <div className='row'>
          <label className='col-1 col-form-label' htmlFor='p1'>
            paragraph1:
          </label>
          <div className='col-11'>
            <input
              name='p1'
              type='text'
              id='p1'
              className='form-control'
              value={editCat.p1}
              onChange={handleChange}
              placeholder='paragraph1'
            />
          </div>
        </div>
        <div className='row'>
          <label className='col-1 col-form-label' htmlFor='p2'>
            paragraph2:
          </label>
          <div className='col-11'>
            <input
              name='p2'
              type='text'
              id='p2'
              className='form-control'
              value={editCat.p2}
              onChange={handleChange}
              placeholder='paragraph2'
            />
          </div>
        </div>
        <div className='row'>
          <label className='col-1 col-form-label' htmlFor='p3'>
            paragraph3:
          </label>
          <div className='col-11'>
            <input
              name='p3'
              type='text'
              id='p3'
              className='form-control'
              value={editCat.p3}
              onChange={handleChange}
              placeholder='paragraph3'
            />
          </div>
        </div>
        <div className='row'>
          <label className='col-1 col-form-label' htmlFor='p4'>
            paragraph4:
          </label>
          <div className='col-11'>
            <input
              name='p4'
              type='text'
              id='p4'
              className='form-control'
              value={editCat.p4}
              onChange={handleChange}
              placeholder='paragraph4'
            />
          </div>
        </div>
        <div className='row'>
          <label className='col-1 col-form-label' htmlFor='p5'>
            paragraph5:
          </label>
          <div className='col-11'>
            <input
              name='p5'
              type='text'
              id='p5'
              className='form-control'
              value={editCat.p5}
              onChange={handleChange}
              placeholder='paragraph5'
            />
          </div>
        </div>

        <div className='col-12'>
          <h5>Steeping (brewing) recomendations:</h5>
        </div>
        <div className='row'>
          <label className='col-1 col-form-label' htmlFor='recWater'>
            Water:
          </label>
          <div className='col-11'>
            <input
              name='recWater'
              type='text'
              id='recWater'
              className='form-control'
              value={editCat.recWater}
              onChange={handleChange}
              placeholder='Water'
            />
          </div>
        </div>
        <div className='row'>
          <label className='col-1 col-form-label' htmlFor='recTemp'>
            Temperature:
          </label>
          <div className='col-11'>
            <input
              name='recTemp'
              type='text'
              id='recTemp'
              className='form-control'
              value={editCat.recTemp}
              onChange={handleChange}
              placeholder='Temperature'
            />
          </div>
        </div>
        <div className='row'>
          <label className='col-1 col-form-label' htmlFor='recTime'>
            Time:
          </label>
          <div className='col-11'>
            <input
              name='recTime'
              type='text'
              id='recTime'
              className='form-control'
              value={editCat.recTime}
              onChange={handleChange}
              placeholder='Time'
            />
          </div>
        </div>
        <div className='row'>
          <label className='col-1 col-form-label' htmlFor='recTimes'>
            Times of brewing:
          </label>
          <div className='col-11'>
            <input
              name='recTimes'
              type='text'
              id='recTimes'
              className='form-control'
              value={editCat.recTimes}
              onChange={handleChange}
              placeholder='Times of brewing'
            />
          </div>
        </div>
        <hr />
      </div>
    </form>
  );
}
