import React from 'react';

export default function ProductCategoryColumns() {
  return (
    <div className='text-white bg-primary font-weight-bold'>
      <div className='row'>
        <div className='col-11 col-lg-2'>name</div>
        <div className='col-11 col-lg-1'>名称</div>
        <div className='col-11 col-lg-3'>url</div>
        <div className='col-11 col-lg-1'>catID</div>
        <div className='col-11 col-lg-2'>image</div>
        <div className='col-11 col-lg-1'>active</div>
        <div className='col-11 col-lg-1'>save/edit</div>
        <div className='col-11 col-lg-1'></div>
      </div>
    </div>
  );
}
