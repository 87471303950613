/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';

import OrderItem from './OrderItem';
import AuthContext from '../../context/AuthContext';

function MyOrders() {
  const { token } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const authStr = 'Bearer ' + token;
      try {
        const result = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/orders/byToken',
          {
            headers: { Authorization: authStr },
          }
        );
        setOrders([...result.data]);
      } catch (error) {
        console.error(error);
      }
    };

    if (!!token) {
      fetchOrders();
    }
  }, [token]);

  return (
    <React.Fragment>
      <h1>My Orders</h1>
      <div className='container-fluid mt-3'>
        <div className='card-group'>
          {orders.map((item) => {
            return <OrderItem key={item._id} order={item} />;
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default MyOrders;
